import React from 'react';
import Markdown, { MarkdownOptions } from 'markdown-to-jsx';
import { Magnifier } from 'react-image-magnifiers';

const FactSheetCarbonTable: React.FC = ({ children, ...props }) => (
    <div className="div-as-paragraph">
        <table {...props}>
            {children}
        </table>
    </div>
);

const FactSheetAnchor: React.FC = ({ children, ...props }) => (
    <a {...props} target='_blank'>
        {children}
    </a>
);

const FactSheetUL: React.FC = ({ children, ...props }) => (
    <div className='div-as-paragraph'>
        <ul {...props}>
            {children}
        </ul>
    </div>
);

const FactSheetOL: React.FC = ({ children, ...props }) => (
    <div className='div-as-paragraph'>
        <ol {...props}>
            {children}
        </ol>
    </div>
);

const FactSheetPre: React.FC = ({ children, ...props }) => (
    <div className="div-as-paragraph">
    	<pre {...props}>
    	    {children}
    	</pre>
    </div>
);

const FactSheetParagraph: React.FC = ({ children, ...props }) => {
    return <div {...props}>
        {children}
    </div>
};

const FactSheetImg: React.FC<{title: string, src: string, alt: string}> = ({ children, ...props }) => (
    <div className="fs-img">
    	<Magnifier
            imageSrc={props.src}
            imageAlt={props.alt}
        />
    </div>
);

export const stringifiable = ["string", "float", "int", "boolean"];
export const fromJson = ["array", "object" ]

const md_options : MarkdownOptions = {
    overrides: {
        table: {
            component: FactSheetCarbonTable,
            props: {
                className: 'bx--data-table bx--data-table--short'
            }
        },
        a: {
            component: FactSheetAnchor
        },
        ul: {
            component: FactSheetUL
        },
        ol: {
            component: FactSheetOL
        },
        pre: {
            component: FactSheetPre
        },
        img: {
            component: FactSheetImg
        },
        p: {
            component: FactSheetParagraph,
            props: {
                className: 'div-as-paragraph'
            }
        },
        span: {
            component: FactSheetParagraph,
            props: {
                className: 'div-as-paragraph'
            }
        }
    }
};

export const renderValue = (type: string, value: any) : React.ReactElement => {
    let content : React.ReactElement = <></>;
    if (type === "markdown") {
        content = <Markdown options={md_options}>{(value as string)}</Markdown>
    } else if (stringifiable.includes(type)) {
        content = <div className="div-as-paragraph">{value as string}</div>
    } else if (fromJson.includes(type)) {
        content = <Markdown options={md_options}>{`\`\`\`\n${JSON.stringify(value, null, 2)}\n\`\`\``}</Markdown>
    } else if (type === "table") {
        const table = value as string[][];
        content = <table className="bx--data-table"><tbody>
            {table.map((row) => (<tr>{row.map( (data) => (<td>{addLineBreaks(data)}</td>) )}</tr>))}
            </tbody></table>
    }
    return content
}

const addLineBreaks = (data: string) => {
    return data.split("\n").map( (word) => (
        <><span>{word}</span><br/></>
    ))
}

export function makeReadable(modelId: string) : string {
    return modelId.replace("max_", "").split("_").join(" ");
}
