import { TagTypeName } from 'carbon-components-react';

export type TagType = {
    color: TagTypeName;
    text: string;
};

export const AuthorNotesTag : TagType = { color: "green", text: "Author Notes" };
export const RobustnessTag : TagType = { color: "blue", text: "Robustness" };
export const EnsembleTag : TagType = { color: "cool-gray", text: "Ensemble" };
export const CollectionTag : TagType = { color: "magenta", text: "Collection" };
export const GovernanceTag : TagType = { color: "teal", text: "Governance" };
export const BiasTag : TagType = { color: "purple", text: "Bias" };
export const PrivacyTag : TagType = { color: "cyan", text: "Privacy" };

export const availableTags : { type: TagType, description: string }[] = [
    { type: AuthorNotesTag, description: "Contains notes about individual facts by the FactSheet author"},
    { type: RobustnessTag, description: "Includes information on testing for robustness against adversarial attacks"},
    { type: EnsembleTag, description: "A combination of one or more models used to solve a particular problem"},
    { type: CollectionTag, description: "Multiple related models each providing an output"},
    { type: GovernanceTag, description: "Contains facts collected throughout the entire AI lifecycle"},
    { type: BiasTag, description: "Includes information on testing for model bias and fairness"},
    { type: PrivacyTag, description: "Includes information on testing for AI model privacy"}
]

export const getTagDescription = (tag: TagType) : string => {
    return availableTags.find( td => td.type === tag)?.description ?? "N/A";
}