import React from 'react';
import { SimpleContentPage } from '../../components';

import './FAQs.css';
import { NavLink } from 'react-router-dom';
import { Link } from 'carbon-components-react';

const faqs = [
    {
        question: "What is a FactSheet?",
        answer: <>A FactSheet documents key Facts about how an AI model or service<sup className="faq-asterisk">*</sup> was developed, tested, deployed, monitored, and modified over time. Facts are captured, stored, retrieved, and viewed in support of AI transparency, trust, and governance. 
        <br/><br/>
        <em><sup className="faq-asterisk">*</sup> We use the term "service" to mean a service or application.</em>
        </>
    },
    {
        question: "What is a Fact?",
        answer: <>A Fact is a unit of information about an AI model or service. Facts typically include model purpose, datasets used for training and testing, model architecture, accuracy and fairness metrics, expected and realized performance including drift over time. Facts are generated throughout the entire AI lifecycle. Some Facts are automatically generated by tools. Other Facts are created by people performing various roles within the AI lifecycle.
        </>
    },
    {
        question: "What is the difference between FactSheets and traditional logging in a software service or system?",
        answer: <>Software logging generally captures only the execution behavior of a deployed service or system. A FactSheet contains information from the full lifecycle starting with the earliest stages of model creation through model deployment, execution monitoring, and potential modification. In contrast to software logging, FactSheets aggregate information arising from multiple people and tools, including information that is not currently found in logs. In contrast to logging, FactSheets also contain only a selected portion of the information that is typically recorded in any one software log.</>
    },
    {
        question: "Why would I want to have a FactSheet for my AI model or service?",
        answer: <>FactSheets capture useful Facts that might otherwise be lost during the development, deployment, and monitoring of an AI model or service. FactSheets provide information tailored for specific audiences and needs, supporting transparency, trust, and governance.</>
    },
    {
        question: "Can any set of Facts be combined to form a FactSheet?",
        answer: <>Yes. Through the use of a Template, the set of facts to include in a FactSheet can be specified. This enables the set of Facts to be tailored for specific audiences or specific needs. The only constraint is that a Fact must have been defined and stored before it can be retrieved and displayed in a FactSheet.</>
    },
    {
        question: "What is a FactSheet Template?",
        answer: <>There are potentially many Facts gathered during the AI lifecycle. A FactSheet Template provides a convenient way to select only the subset of Facts that are useful for a particular purpose.</>
    },
    {
        question: "Can there be multiple FactSheets for a single AI service?",
        answer: <>Yes. An AI service can combine multiple models. Each model can have its own FactSheet, especially if that model can be used in other services. The service itself can also have a FactSheet describing how its multiple models are combined to produce an output.</>,
    },
    {
        question: "What is the relationship between Facts, FactSheets, and the different parts of the AI lifecycle?",
        answer: <>A more detailed description can be found in the <NavLink to="/governance">Governance</NavLink> section, but at a high level, different phases of the AI lifecycle generate Facts that are associated with a model or service. The goal is to provide a mechanism to support the collection and storage of Facts so they can be retrieved and rendered as needed. FactSheets are simply different ways to select and view these Facts. The same Fact can also appear in different FactSheets to meet the information needs of various stakeholders at different points within the AI lifecycle.</>,
    },
    {
        question: "What are the different ways FactSheets can be rendered?",
        answer: <>FactSheets can be rendered in whatever way fits the needs of the person viewing them. We have explored rendering Facts inside a Jupyter notebook, a tool dashboard, a summary web page, a pdf document, and a slide presentation.</>,
    },
    {
        question: "Will FactSheets be available in IBM Products (e.g. OpenScale, Watson Studio, etc.)?",
        answer: <>Yes, Facts and FactSheets will be an integral part of <Link href="https://www.ibmbigdatahub.com/blog/ai-governance-drive-compliance-efficiency-and-outcomes-your-ai-lifecycle" target="_blank">IBM's AI Governance platform</Link>.
        </>,
    },
    {
        question: "How do FactSheets compare to DataSheets, Model Cards, and the EU Trusted AI Assessment list?",
        answer: <>All of these efforts are trying to increase the transparency of AI models and services. We are collectively learning how best to document AI models and services. AI FactSheets have introduced particular ideas into this effort such as the customization of content and presentation of Facts with a FactSheet Template, the documentation of the full lifecycle of an AI model or service, and the mechanism for linking Facts to the larger process of AI Governance.</>
    }
]

export const FAQs = () => (
    <SimpleContentPage key="FAQs" title="Frequently Asked Questions">
        <ol className="faq-list">
        {
            faqs.map( (section) => (
                <li key={section.question}>
                    <span className="question">{section.question}</span><br/>
                    <span className="answer">{section.answer}</span><br/><br/>
                </li>
            ))
        }
        </ol>
    </SimpleContentPage>
)