import React from 'react';
import './App.scss';

import { Content } from 'carbon-components-react';
import { FactSheetsHeader } from './components';
import { Route, Switch } from 'react-router-dom';
import { Home, Introduction, RelatedWork, Events, Videos, NotFound, UnderConstruction, SlackCommunity, Papers, NewsCoverage, FAQs, Methodology, Governance, ExamplesOverview, Glossary } from './content';
import { examplesInfo } from './assets/examples';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <FactSheetsHeader/>
      </header>
      <Content>
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/introduction" component={Introduction} />
            <Route exact path="/methodology" component={Methodology} />
            { /* examples */ }
            <Route exact path="/examples" component={ExamplesOverview} />
            {
              examplesInfo.map( (ex) => {
                const content = ex.active ?
                  <ex.component model_tags={ex.tags} key={ex.id} model_id={ex.id} model_name={ex.text} model_url={ex.url} subtitle={ex.subtitle} /> :
                  <UnderConstruction/>
                return <Route key={ex.id} exact path={`/examples/${ex.id}`}>{content}</Route>
              })
            }
            <Route exact path="/governance" component={Governance} />

            { /* resources */ }
            <Route exact path="/resources/slack-community" component={SlackCommunity} />
            <Route exact path="/resources/papers" component={Papers} />
            <Route exact path="/resources/videos" component={Videos} />
            <Route exact path="/resources/events" component={Events} />
            <Route exact path="/resources/related-work" component={RelatedWork} />
            <Route exact path="/resources/glossary" component={Glossary} />
            <Route exact path="/resources/faqs" component={FAQs} />
            <Route exact path="/resources/news-coverage" component={NewsCoverage} />
            <Route component={NotFound} />
        </Switch>
      </Content>
    </div>
  );
}

export default App;
