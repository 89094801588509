import React, { Fragment } from 'react';
import { slugify } from '../utils';

export type Section = {
    title: string,
    content: React.ReactNode;
}

export const SectionList: React.FC<{sections: Section[]}> = ( { sections } ) => (
    <>
    {
    sections.map( (section) => (
        <Fragment key={section.title}>
            <h3 id={slugify(section.title)}>{section.title}</h3><br/>
            <div className="div-as-paragraph">{section.content}</div><br/><br/>
        </Fragment>
    ))
    }
    </>
)