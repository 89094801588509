import React from "react";
import { Link } from "carbon-components-react";

type NewsItem = {
    name: string,
    publication:string,
    url: string,
    date?: string,
}

export const featuredNewsItems : NewsItem[] = [
    {
        name: "Why IBM’s AI Fact Sheets should be the industry standard",
        publication: "TheNextWeb",
        url: "https://thenextweb.com/neural/2020/12/09/why-ibms-ai-fact-sheets-should-be-the-industry-standard/",
        date: "2020/12/10"
    },
]

export const newsItems : NewsItem[] = [
    {
        name: "IBM exec said firm's new tool is like a 'nutrition' label for AI, providing detailed information to help flag bias or inaccuracy",
        publication: "Business Insider",
        url: "https://www.businessinsider.com/ibm-ai-factsheets-label-system-bias-data-2020-12",
        date: "2020/12/14"
    },
    {
        name: "IBM Commercialises Its AI FactSheets. Could It Become An Industry Standard?",
        publication: "Analytics India Magazine",
        url: "https://analyticsindiamag.com/ibm-commercialises-its-ai-factsheets-could-it-become-an-industry-standard/",
        date: "2020/12/14"
    },
    {
        name: "IBM Launches New Innovative Capabilities for Watson",
        publication: "ET CIO",
        url: "https://cio.economictimes.indiatimes.com/news/corporate-news/ibm-launches-new-innovative-capabilities-for-watson/79675978",
        date: "2020/12/11"
    },
    {
        name: "IBM Launches New Innovative Capabilities for Watson; Leading Global Brands including EY and Regions Bank use Watson to Infuse Intelligence into Workflows",
        publication: "Yahoo! Finance",
        url: "https://finance.yahoo.com/news/ibm-launches-innovative-capabilities-watson-130000723.html",
        date: "2020/12/09"
    },
    {
        name: "IBM announces new AI language, explainability, and automation services",
        publication: "VentureBeat",
        url: "https://venturebeat.com/2020/12/09/ibm-announces-new-ai-language-explainability-and-automation-services/",
        date: "2020/12/09"
    },
    {
        name: "IBM adds Watson tools for reading comprehension, FAQ extraction",
        publication: "ZDNet",
        url: "https://www.zdnet.com/article/ibm-adds-watson-tools-for-reading-comprehension-faq-extraction/",
        date: "2020/12/09"
    },
    {
        name: "IBM creates knowledgeable NLP system and adds AI governance capabilities to Watson",
        publication: "TechRepublic",
        url: "https://www.techrepublic.com/article/ibm-creates-knowledgeable-nlp-system-and-adds-ai-governance-capabilities-to-watson/",
        date: "2020/12/09"
    },
    {
        name: "IBM launches new innovative capabilities for Watson",
        publication: "United News of India",
        url: "http://www.uniindia.com/ibm-launches-new-innovative-capabilities-for-watson/east/news/2259404.html",
        date: "2020/12/10"
    },
    {
        name: "IBM Launches New Innovative Capabilities for Watson; Leading Global Brands including EY and Regions Bank use Watson to Infuse Intelligence into Workflows",
        publication: "PR Newswire",
        url: "https://www.prnewswire.co.uk/news-releases/ibm-launches-new-innovative-capabilities-for-watson-leading-global-brands-including-ey-and-regions-bank-use-watson-to-infuse-intelligence-into-workflows-822080923.html",
        date: "2020/12/10"
    },
    {
        name: "IBM Launches New Innovative Capabilities for Watson",
        publication: "Technuter",
        url: "http://technuter.com/artificial-intelligence/ibm-launches-new-innovative-capabilities-for-watson.html",
        date: "2020/12/10"
    },
];

const renderNewsItems = (newsItems: NewsItem[]) => (
    <ul>
     {
     newsItems.map( (newsItem) => (
        <li className="news-coverage-item" key={newsItem.name}>
            <Link href={newsItem.url} target="_blank"><strong>{newsItem.name}</strong></Link>
            <br/> {newsItem.publication}
            { newsItem.date && <>, { "(published on " + newsItem.date + ")"}</>}
        </li>
    ))}
    </ul>
)


export const renderAdditionalNewsItems = () => (
    renderNewsItems(newsItems)
)

export const renderFeaturedNewsItems = () => (
    renderNewsItems(featuredNewsItems)
)