import React, { Fragment } from 'react';
import { SimpleContentPage, slugify } from '../../components';

import './Governance.css'
import { Link, TooltipDefinition } from 'carbon-components-react';
import { NavLink } from 'react-router-dom';

const GovernanceImage = ({ text, img, cls } : { text: string, img: string, cls?: string} ) => (
    <div className={`governance-figures ${ cls || "" }`}>
        {/* <div className="governance-img-text">{text}</div> */}
        <div className="governance-img-image">
            <img alt={text} src={img}></img>
        </div>
    </div>
)

const GovernanceTable = ({ tableName, data, mobile }: {tableName: string, data: any, mobile?: boolean}) => {
    const non_mobile_table = <>
    { Object.keys(data).map( (part) => {
        return <div className={`${tableName} ${part}`}>
        <table className='bx--data-table bx--data-table--short bx--data-table--no-border governance-table'>
            <thead>
                <tr>{ data[part].headers.map((h, ix) => <th key={`${h}-${ix}`}>{h}</th>) }</tr>
            </thead>
            <tbody>
                { data[part].rows.map( (r) => <tr>{ r.map( (val, ix) => <td className={`columns-${r.length} col-${ix}`}>{val}</td>) }</tr>) }
            </tbody>
        </table>
    </div>
    })}</>

    const mobile_table =
        <>
        { Object.keys(data).map( (part) => {
            return <div className={`${tableName} ${part}`}>
            <table className='bx--data-table bx--data-table--short bx--data-table--no-border governance-table'>
                <thead>
                    <tr>
                        <th colSpan={2}>{data[part].headers[0]}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data[part].rows.map( (r) =>
                            <tr>
                                <td className="mobile-cell col-0">
                                    <div>{r[0]}</div>
                                    {
                                        r.slice(1).map( (val, ix) => (<div className="mobile-cell-values">
                                            {/* {`(${ix+2}${ordinals[ix+2]} col) `} */}
                                            {data['part1'].headers[ix+1]}: {val}
                                        </div>))
                                    }
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
        })}
        </>

    return <>
        <div className="governance-table desktop">
            {non_mobile_table}
        </div>
        <div className="governance-table mobile">
            { mobile ? mobile_table : non_mobile_table }
        </div>
    </>
}

const table1Data = {
    'part1': {
        headers: ["Business Owner Fact", "Value"],
        rows: [
            ["Model purpose", "Predict mortgage approval"],
            ["Risk level", "High"],
        ]
    },
}

const table2Data = {
    'part1': {
        headers: ["Predictive Performance", "Data Scientist's Dataset", "Validator's Dataset"],
        rows: [
            ["Accuracy", 0.95, 0.94],
            ["Balanced Accuracy", 0.63, 0.63],
            ["AUC", 0.79, 0.78],
            ["F1", 0.97, 0.97],
        ]
    },
    'part2': {
        headers: ["Fairness", "", ""],
        rows: [
            ["Disparate Impact", 0.97, 0.97],
            ["Statistical Parity Difference", -0.03, -0.03],
        ]
    },
    'part3': {
        headers: ["Adversarial Robustness", "", ""],
        rows: [
            ["Empirical Robustness", 0.02, 0.01],
        ]
    },
    'part4': {
        headers: ["Explainability", "", ""],
        rows: [
            ["Faithfulness Mean", 0.31, 0.36],
        ]
    }
}

const table3Data = {
    'part1': {
        headers: ["Predictive Performance", "Data Scientist's Model", "Challenge Model"],
        rows: [
            ["Accuracy", 0.94, 0.89],
            ["Balanced Accuracy", 0.63, 0.62],
            ["AUC", 0.78, 0.62],
            ["F1", 0.97, 0.93],
        ]
    },
    'part2': {
        headers: ["Fairness", "", ""],
        rows: [
            ["Disparate Impact", 0.97, 0.94],
            ["Statistical Parity Difference", -0.03, -0.06],
        ]
    },
    'part3': {
        headers: ["Adversarial Robustness", "", ""],
        rows: [
            ["Empirical Robustness", 0.01, 0.13],
        ]
    },
    'part4': {
        headers: ["Explainability", "", ""],
        rows: [
            ["Faithfulness Mean", 0.36, 0.87],
        ]
    }
}

const table4Data = {
    'part1': {
        headers: ["Predictive Performance", "Data Scientist's Dataset", "Validator's Dataset", "Deployment Data"],
        rows: [
            ["Accuracy", 0.95, 0.94, 0.92],
            ["Balanced Accuracy", 0.63, 0.63, 0.61],
            ["AUC", 0.79, 0.78, 0.77],
            ["F1", 0.97, 0.97, 0.96],
        ]
    },
    'part2': {
        headers: ["Fairness", "", "", ""],
        rows: [
            ["Disparate Impact", 0.97, 0.97, 0.95],
            ["Statistical Parity Difference", -0.03, -0.03, -0.04],
        ]
    },
    'part3': {
        headers: ["Adversarial Robustness", "", "" , ""],
        rows: [
            ["Empirical Robustness", 0.02, 0.01, 0.02],
        ]
    },
    'part4': {
        headers: ["Explainability", "", "", ""],
        rows: [
            ["Faithfulness Mean", 0.31, 0.36, 0.35],
        ]
    }
}

const sections = [
    // {
    //     title: "AI Governance Overview",
    //     content:
    //     <div className="video-constraint">
    //         <div className="videoWrapper">
    //             <iframe title="AI Governance Overview" frameBorder="0" allowFullScreen width="560" height="315" src="https://www.youtube.com/embed/-4d3kEVsu-s" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
    //         </div>
    //     </div>
    // },
    {
        title: "The Need for Governance",

        content: <>Enterprises creating AI <TooltipDefinition triggerClassName="intro-term-definition" align="center" tooltipText={'We use the term "service" to mean a service or application.'}>services</TooltipDefinition> are being challenged by an emerging problem: How to effectively govern the creation and deployment of these services. Enterprises want to understand and gain control over their current AI lifecycle processes, often motivated by internal policies or external regulation.
        <br/><br/>
        <NavLink to="/examples/hmda">View a FactSheet captured from a sample AI lifecycle</NavLink>.
        </>
    },
    {
        title: "The AI Lifecycle",
        content: <>The AI lifecycle includes a variety of roles, performed by people with different specialized skills and knowledge that collectively produce an AI service. Each role contributes in a unique way, using different tools. Figure 1 specifies some common roles.

        <GovernanceImage text="Roles" img="Img1_Roles.png" cls="roles"/>
        <div className="figure-caption">Figure 1: The roles in the AI lifecycle</div>
        <br/>
        The process starts with a business owner who requests the construction of an AI model (or service). The request includes the purpose of the model or service, how to measure its effectiveness, and any other constraints, such as bias thresholds, appropriate datasets, or levels of explainability and robustness required.
        <br/><br/>
        The data scientist uses this information to construct a candidate model by using, most typically, a machine learning process. This iterative process includes selecting and transforming the dataset, discovering the best machine learning algorithm, tuning algorithm parameters, etc. The goal is to produce a model that best satisfies the requirements set by the business owner.
        <br/><br/>
        Before this model is deployed it must be tested by an independent person, referred to as a model validator in Figure 1. This role, often falling within the scope of model risk management, is similar to a testing role in traditional software development. A person in this role applies a different dataset to the model and independently measures metrics defined by the business owner. If the validator approves the model it can be deployed.
        <br/><br/>
        The AI operations engineer is responsible for deploying and monitoring the model in production to ensure it operates as expected. This can include monitoring its performance metrics, as defined by the business owner. If some metrics are not meeting expectations, the operations engineer is responsible for taking actions and informing the appropriate roles.
        <br/><br/>
        We use this example to illustrate the AI Governance scenario. Real lifecycles will likely have additional roles. A common pattern is for a model to be combined with other models or human-written code to form a service. In such a case the validator's role will be extended to also validate the full service.
        <br/><br/>
        Real lifecycles will also involve iteration throughout the lifecycle, such as within a role (a data scientist building many models before passing it to a validator) or between roles (an operations person sending a model back to a data scientist because it is performing poorly).
        </>
    },
    {
        title: "AI Facts",
        content: <>
        A key requirement for enabling AI Governance is the ability to collect model Facts throughout the AI lifecycle. This set of facts can be used to create a FactSheet for the model or service. Figure 2 illustrates how facts can be captured from the activities of the various lifecycle roles.

        <GovernanceImage text="Facts" img="Img2_Facts.png" cls="facts"/>
        Figure 2: AI model facts being generated by different AI lifecycle roles
        <br/><br/>
        Capturing model facts in a common place provides shareable knowledge about the model. These facts can then be queried by any role in the lifecycle or outside of it, as shown in Figure 3. These queries can be conveniently made using <NavLink to="/introduction#templates:_one_size_does_not_fit_all">FactSheet Templates</NavLink> to obtain a customized view of the Facts appropriate to the role. Some examples might be:
        <ul className="gov-examples">
            <li>
                a business owner wanting to know all of the model facts about the model they requested
            </li>
            <li>
                a model validator, comparing the facts of a data scientist-produced model to a simpler challenge model
            </li>
            <li>
                a manager presenting a summary of the facts in a slide format to an executive
            </li>
        </ul>

        <GovernanceImage text="Roles" img="Img3_Views.png" cls="views"/>
        Figure 3: Model facts consumed by lifecycle roles and enterprise-wide roles to meet their specific needs
        <br/><br/>
        Providing this level of insight into the AI lifecycle can help enterprises understand the effectiveness of their current processes and gain insights on how to improve them. This information can be used to enforce enterprise governance policies, by not allowing the process to advance to the next stage if a fact condition is not met. This provides a needed level of control to minimize risk and ensure compliance with external regulations.
        </>
    },
    {
        title: "Enabling Governance",
        content: <>As described above, AI Facts and FactSheets can be the cornerstone of AI governance by providing enterprises with the ability to
        <ul>
            <li>capture AI lifecycle facts, enabling greater visibility and automated documentation</li>
            <li>perform analysis of these facts to improve business outcomes, increase overall  efficiency, and learn best practices</li>
            <li>specify enterprise policies to be enforced during the AI development and deployment lifecycle</li>
            <li>facilitate communication and collaboration among the diverse lifecycle roles and other stakeholders</li>
        </ul>
        <br/>
        Discover more about <NavLink to="#ibm_offerings">IBM's AI Governance platform</NavLink>.
        </>
    },
    {
        title: "An Example",
        content:<>
        To make these concepts more concrete, we show some example facts for each of the four lifecycle roles mentioned above: business owner, data scientist, model validator, and AI operations engineer. The facts capture information about the development and deployment of a model for assisting mortgage approval. We leverage the <Link href="https://ffiec.cfpb.gov/data-publication/snapshot-national-loan-level-dataset/2018" target="_blank">Home Mortgage Disclosure Act dataset</Link> from the <Link href="https://www.consumerfinance.gov/" target="_blank">US Consumer Finance Protection Bureau</Link>, which contains information about each mortgage applicant (the features) and the approval outcome (label). The label is used for training and for evaluating the trained model.
        <br/><br/>
        We leverage open source toolboxes for measuring the fairness (<Link href="https://aif360.res.ibm.com/" target="_blank">AI Fairness 360</Link>), adversarial robustness (<Link href="https://art360.res.ibm.com/" target="_blank">AI Adversarial Robustness 360</Link>), and explainability (<Link href="https://aix360.res.ibm.com/" target="_blank">AI Explainability 360</Link>) of the model. In practice, we expect the volume of facts to be much larger than what we show below and to be customized to a particular enterprise's interest. We present a subset of facts to illustrate how the facts are provided by the various roles in the lifecycle and accrue over the course of the lifecycle.
        <br/><br/>
        Table 1 shows the first sets of facts in the lifecycle, which come from the business owner. These facts are the purpose of the model and the risk level of the model to the company. The model purpose fact can be useful for determining if the model might be applicable in a different circumstance, such as a car loan approval scenario. The risk level fact can be useful for determining the level of validation needed for this model. Higher risk models should be validated more thoroughly.
        <br/><br/>
        <GovernanceTable tableName="table1" data={table1Data}/>
        <div className="figure-caption">
            Table 1: Sample facts from business owner
        </div>
        <br/>
        For the data scientist, model validator, and AI operations engineer, we focus on a set of facts related to the model's predictive performance, fairness, adversarial robustness, and explainability. Focusing on these four dimensions demonstrates the diversity of facts.
        <br/><br/>
        Table 2 shows the facts for various measures of accuracy, fairness, adversarial robustness, and explainability of the model (1st column). The definitions of these metrics can be found in machine learning literature (accuracy) or in the open source toolkits mentioned above (fairness, robustness, explainability). The table provides values for these metrics measured on the data scientist's dataset (2nd column) and the model validator's independent dataset (3rd column). We see small differences in the values consistent with a successful validation of the model.
        <br/><br/>
        <GovernanceTable tableName="table2" data={table2Data} mobile={true}/>
        <div className="figure-caption">Table 2: Model facts from data scientist and validator</div>
        <br/>
        Another common test performed by model validators is to determine if the performance of the created model can be replicated by using a simpler "challenge" model. If this is possible, an enterprise will likely prefer the simpler model because it is easier to understand and thus, poses less risk. Table 3 illustrates the performance of the data scientist's model (2nd column) versus a challenge model (3rd column) created by the model validator on the same dataset. The table shows that the data scientist's model performs better and is therefore a good candidate to be deployed.
        <br/><br/>
        <GovernanceTable tableName="table3" data={table3Data} mobile={true}/>
        <div className="figure-caption">Table 3: Model facts from data scientist's model and the validator's challenge model</div>
        <br/>
        Our final set of facts comes from the AI operations engineer and is shown in Table 4. As in Table 2, we have the same model created by the data scientist run on the data scientist's dataset (2nd column) and the validator's dataset (3rd column). The deployment facts (4th column) represent the model's performance on input seen by the deployed system. We see the model's performance has some degradation with the deployment data. An AI operations engineer would continue to monitor the performance of the model, using a platform like <Link href="https://www.ibm.com/cloud/watson-openscale" target="_blank">Watson OpenScale</Link> and if the performance degrades, could contact the data scientist for further investigation or the business owner to decide if the model should be retired.
        <br/><br/>
        <GovernanceTable tableName="table4" data={table4Data} mobile={true}/>
        <div className="figure-caption">Table 4: Model facts from data scientist, validator, and AI operations engineer</div>
        <br/>
        The facts shown in Table 4, although only a subset of the large number of facts that could be collected, illustrate the value of collecting these facts throughout the lifecycle. In particular, all four roles can use this information to be more informed for future actions. 
        <ul>
            <li>
                The business owner can easily see how the model that they requested performed after creation (2nd column), during validation (3rd column), and in live deployment (4th column). Large discrepancies in these metrics between stages might trigger further analysis (of the model and, possibly, of the development processes used throughout the lifecycle).   
            </li>
            <li>
                The data scientist and model validator can use the information to understand how effective their approaches are to developing and validating models that perform well when deployed.
            </li>
            <li>
                The AI operations engineer can use the information to understand the relationships between metrics generated prior to deployment and the metrics generated in production (leading, perhaps, to improvements in the metrics used by the model developer and validator).
            </li>
        </ul> 
        <br/>
        Each of the tables in this section can be considered as at least a portion of a FactSheet; a collection of facts about a model that is tailored to the consumer's needs leveraging the Templates mentioned in the <NavLink to="/introduction">Introduction</NavLink> section. The Examples section of this website shows more complete FactSheets for several real models.
        </>
    },
    {
        title: "IBM Offerings",
        content: <ul>
            <li><Link href="https://www.ibm.com/blogs/journey-to-ai/2022/10/ai-governance-break-open-the-black-box/" target="_blank"><strong>AI Governance: Break open the black box (Oct 4, 2022)</strong></Link></li>
            <li><Link href="https://medium.com/ibm-data-ai/ai-factsheets-on-cloud-pak-for-data-as-a-service-automate-collection-of-model-facts-across-the-ai-503400414641" target="_blank"><strong>AI Factsheets on Cloud Pak for Data as a Service: Automate collection of model facts across the AI Lifecycle (Jan 17, 2022)</strong></Link></li>
            <li><Link href="https://www.ibm.com/blogs/watson/2021/06/ai-governance-maturity/" target="_blank"><strong>Foundations of trustworthy AI: How mature is your AI governance? (Jun 8, 2021)</strong></Link></li>
            <li><Link href="https://www.ibm.com/blogs/watson/2020/12/how-ibm-is-advancing-ai-governance-to-help-clients-build-trust-and-transparency/" target="_blank"><strong>How IBM is advancing AI governance to help clients build trust and transparency (Dec 9, 2020)</strong></Link></li>
            <li><Link href="https://www.ibm.com/blogs/journey-to-ai/2020/05/ai-governance-drive-compliance-efficiency-and-outcomes-from-your-ai-lifecycle/" target="_blank"><strong>AI Governance: Drive compliance, efficiency and outcomes from your AI lifecycle (May 26, 2020)</strong></Link></li>
            <li><Link href="https://www.ibm.com/analytics/common/smartpapers/ai-governance-smartpaper/" target="_blank"><strong>AI Governance: Ensuring your AI is transparent, compliant, and trustworthy (May 26 2020)</strong></Link></li>
            <li><Link href="https://www.youtube.com/watch?v=-4d3kEVsu-s" target="_blank"><strong>AI Governance Video (Dec 9, 2020)</strong></Link></li>
        </ul>
    },
]

export const Governance = () => (
    <SimpleContentPage key="Governance" title="AI Lifecycle Governance">
        {
            sections.map( (section) => (
                <Fragment key={section.title}>
                    <h3 id={slugify(section.title)}>{section.title}</h3><br/>
                    <div className="div-as-paragraph">{section.content}</div><br/><br/>
                </Fragment>
            ))
        }
        <div className="blank-space"></div>
    </SimpleContentPage>
)