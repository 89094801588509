import { Example, MortgageExample } from "../content";
import { ExampleSimple } from "../content/Examples/ExampleSimple";
import { ExampleProps } from "../content/Examples/utils";
import { TagType, AuthorNotesTag, EnsembleTag, RobustnessTag, CollectionTag, GovernanceTag, BiasTag, PrivacyTag } from "./tags";

type ExamplesInfo = {
    id: string,
    component: React.FC<ExampleProps>,
    active: boolean;
    text: string;
    description: string;
    url: string;
    tags: TagType[];
    subtitle: string;
};

export const examplesInfoModelMarketPlace: ExamplesInfo[] = [
    {
        id:  "max_audio_classifier",
        component: Example,
        active: true,
        text: "Audio Classifier",
        description: "Identify sounds in short audio clips",
        url: "https://developer.ibm.com/blogs/ibm-model-and-data-asset-exchange-updates/",
        tags: [
            AuthorNotesTag,
            EnsembleTag,
            RobustnessTag
        ],
        subtitle: "Created by data scientists for use in an AI model catalog",
    },
    {
        id:  "max_object_detector",
        component: Example,
        active: true,
        text: "Object Detector",
        description: "Localize and identify multiple objects in a single image",
        url: "https://ml-exchange.org/models/max-object-detector",
        tags: [ RobustnessTag ],
        subtitle: "Created by data scientists for use in an AI model catalog",
    },
    {
        id:  "max_image_caption_generator",
        component: Example,
        active: true,
        text: "Image Caption Generator",
        description: "Generate captions that describe the contents of images",
        url: "https://ml-exchange.org/models/max-image-caption-generator",
        tags: [ BiasTag ],
        subtitle: "Created by data scientists for use in an AI model catalog",
    },
    {
        id:  "max_text_sentiment_classifier",
        component: Example,
        active: true,
        text: "Text Sentiment Classifier",
        description: "Detect the sentiment captured in short pieces of text",
        url: "https://ml-exchange.org/models/max-text-sentiment-classifier",
        tags: [ RobustnessTag ],
        subtitle: "Created by data scientists for use in an AI model catalog",
    },
    {
        id:  "max_weather_forecaster",
        component: Example,
        active: true,
        text: "Weather Forecaster",
        description: "Predict hourly weather features given historical data for a specific location",
        url: "https://ml-exchange.org/models/max-weather-forecaster",
        tags: [ CollectionTag ],
        subtitle: "Created by data scientists for use in an AI model catalog",
    },
];

export const examplesInfoGovernance: ExamplesInfo[] = [
    {
        id: "hmda",
        component: MortgageExample,
        active: true,
        text: "Mortgage Evaluator Governance",
        description: "Recommend whether a loan application should be approved",
        url: "",
        tags: [ GovernanceTag ],
        subtitle: null,
    },
    {
      id: "federated_learning",
      component: ExampleSimple,
      active:true,
      text: "Accountable Federated Learning",
      url: "",
      description: "Accountable Federated Learning: A Classifying Citizen Participation Ideas Use Case",
      tags: [],
      subtitle: "A Classifying Citizen Participation Ideas Use Case",
    }
];


export const examplesInfoModelRisk: ExamplesInfo[] = [
    {
        id: "hmda_privacy",
        component: Example,
        active: true,
        text: "Mortgage Evaluator Privacy",
        description: "Privacy evaluation for HMDA example",
        url: "",
        tags: [PrivacyTag ],
        subtitle: "Created by data scientists to assess privacy risk",
    },
];

// Don't forget to concatenate all the examples above for sidebar links
export const examplesInfo: ExamplesInfo[] = examplesInfoModelMarketPlace.concat(examplesInfoGovernance.concat(examplesInfoModelRisk));

// export const examplesInfo: ExamplesInfo[] = [
//     {
//         id:  "max_audio_classifier",
//         component: Example,
//         active: true,
//         text: "Audio Classifier",
//         description: "Identify sounds in short audio clips",
//         url: "https://developer.ibm.com/exchanges/models/all/max-audio-classifier/",
//         tags: [
//             AuthorNotesTag,
//             // RobustnessTag,
//             EnsembleTag
//         ]
//     },
//     {
//         id:  "max_object_detector",
//         component: Example,
//         active: true,
//         text: "Object Detector",
//         description: "Localize and identify multiple objects in a single image",
//         url: "https://developer.ibm.com/exchanges/models/all/max-object-detector/",
//         tags: [ RobustnessTag ]
//     },
//     {
//         id:  "max_weather_forecaster",
//         component: Example,
//         active: true,
//         text: "Weather Forecaster",
//         description: "Predict hourly weather features given historical data for a specific location",
//         url: "https://developer.ibm.com/exchanges/models/all/max-weather-forecaster/",
//         tags: [ CollectionTag ]
//     },
//     {
//         id:  "max_text_sentiment_classifier",
//         component: Example,
//         active: true,
//         text: "Text Sentiment Classifier",
//         description: "Detect the sentiment captured in short pieces of text",
//         url: "https://developer.ibm.com/exchanges/models/all/max-text-sentiment-classifier/",
//         tags: [ RobustnessTag ]
//     },
//     {
//         id:  "max_image_caption_generator",
//         component: Example,
//         active: true,
//         text: "Image Caption Generator",
//         description: "Generate captions that describe the contents of images",
//         url: "https://developer.ibm.com/exchanges/models/all/max-image-caption-generator/",
//         tags: []
//     },
//     {
//         id:  "max_breast_cancer_detection",
//         component: Example,
//         active: true,
//         text: "Breast Cancer Mitosis Detector",
//         description: "Detect whether a mitosis exists in an image of breast cancer tumor cells",
//         url: "https://developer.ibm.com/exchanges/models/all/max-breast-cancer-mitosis-detector/",
//         tags: []
//     },
// ];
