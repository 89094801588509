import React from 'react';
import { SimpleContentPage } from '../../components';
import { Link } from 'carbon-components-react';

export const SlackCommunity = () => (
    <SimpleContentPage key="SlackCommunity" title="Slack Community">
        <p>
        	The FactSheets 360 Project is part of the  <Link href="https://aif360.slack.com" target="_blank">AIF360 Slack Community</Link>.<br/>
        	Engage with others to ask questions and share your knowledge in the <strong>#factsheets</strong> channel.
        </p><br/>

        <p>
            <Link href="https://aif360.slack.com/join/shared_invite/zt-5hfvuafo-X0~g6tgJQ~7tIAT~S294TQ#/" target="_blank">Request an invitation</Link> to the workspace and don't forget to join the <strong>#factsheets</strong> channel!
        </p>
    </SimpleContentPage>
)