import { LinkableText } from "../components";

type Video = {
  title: LinkableText,
  date: string,
  duration: number,
  authors: string[]
}

/** Video Empty Template
{
    title: {
        text: "",
        url: ""
    },
    date: "",
    duration: 0,
    authors: []
}
 */

export const videos: { [category: string]: Video[] } = {
  "Transparency / Governance": [
    {
      title: {
        text: "AI Governance: Driving Compliance, Efficiency, and Outcomes",
        url: "https://www.youtube.com/watch?v=o8W5Z867hBQ"
      },
      date: "2021/3/3",
      duration: 28,
      authors: ["Michael Hind", "Zain Nasrullah (RBC)"]
    },
    {
      title: {
        text: "Race, Tech, and Civil Society: Tools for Combating Bias in Datasets and Models",
        url: "https://www.youtube.com/watch?v=gsHAYvokSdA"
      },
      date: "2021/2/3",
      duration: 94,
      authors: ["Michael Hind"]
    },
    // {
    //   title: {
    //     text: "AI Governance Overview,",
    //     url: "https://youtu.be/-4d3kEVsu-s"
    //   },
    //   date: "2020/12/10",
    //   duration: 3,
    //   authors: [
    //     "IBM News"
    //   ]
    // },
    {
      title: {
        text: "AI FactSheets",
        url: "https://youtu.be/V_ivECnlQGM?start=5655"
      },
      date: "2020/6/17",
      duration: 26,
      authors: [
        "Michael Hind"
      ]
    },
    {
      title: {
        text: "Experiences with Improving the Transparency of AI Models and Services,",
        url: "https://youtu.be/wk18pxCJkwM"
      },
      date: "2020/5/29",
      duration: 7,
      authors: [
        "John Richards"
      ]
    },
  ],

  "Trusted AI Overviews": [
    {
      title: {
        text: "How Can We Trust AI?",
        url: "https://youtu.be/-O1PhA7Mog4"
      },
      date: "2019/10/14",
      duration: 28,
      authors: [
        "Saska Mojsilovic"
      ]
    },
    {
      title: {
        text: "Building trust in AI, the IBM way",
        url: "https://youtu.be/iAjSMBDODS4"
      },
      date: "2018/12/21",
      duration: 14,
      authors: [
        "Saska Mojsilovic"
      ]
    },
    {
      title: {
        text: "Trustworthy AI Overview",
        url: "https://vimeo.com/443438508/9387ed6142"
      },
      date: "2020/07/31",
      duration: 26,
      authors: ["Kush R. Varshney"]
    },
    {
      title: {
        text: "What are the research challenges in Trusted AI?",
        url: "https://mediasite.mms.rpi.edu/Mediasite5/Play/8d8f9972ebcb42c795eb68eb85757ea71d?catalog=afd40849a26e4cf695417855ee8d60d421"
      },
      date: "2020/09/25",
      duration: 34,
      authors: ["Michael Hind"]
    },
  ],

  "Fairness": [
    {
      title: {
        text: "AI Fairness 360",
        url: "https://youtu.be/X1NsrcaRQTE"
      },
      date: "2018/9/20",
      duration: 32,
      authors: [
        "Kush R Varshney"
      ]
    },
    {
      title: {
        text: "AI Fairness 360 Tutorial at FAT*2019 Conference",
        url: "https://www.youtube.com/playlist?list=PL1AhkSNag6_N7if5DrICBg0SJh9kXwC8I"
      },
      date: "2019/1/29",
      duration: 145,
      authors: [
        "Rachel K. E. Bellamy",
        "Michael Hind",
        "Karthikeyan Natesan Ramamurthy",
        "Kush R. Varshney"
      ]
    },
    {
      title: {
        text: "Trusted AI and AI Fairness 360 Tutorial",
        url: "https://youtu.be/IXbG2u4lOYI"
      },
      date: "2019/9/18",
      duration: 73,
      authors: [
        "Prasanna Sattigeri"
      ]
    },
    {
      title: {
        text: "AI Fairness 360",
        url: "https://www.youtube.com/watch?v=HxUqZSB-LFU"
      },
      date: "2019/3/22",
      duration: 48,
      authors: [
        "Kush R. Varshney"
      ]
    },
    {
      title: {
        text: "Removing Unfair Bias in Machine Learning",
        url: "https://community.ibm.com/community/user/datascience/viewdocument/removing-unfair-bias-in-machine-lea"
      },
      date: "2019/11/13",
      duration: 45,
      authors: [
        "Trisha Mahoney",
        "Karthikeyan Natesan Ramamurthy"
      ]
    },
    {
      title: {
        text: "Mitigating Discrimination and Bias with AI Fairness 360",
        url: "https://youtu.be/oAwcJcjRsDE"
      },
      date: "2020/5/6",
      duration: 66,
      authors: [
        "Karthikeyan Natesan Ramamurthy"
      ]
    }
  ],

  "Explainability": [
    {
      title: {
        text: "Making AI more trusted, by making it explainable",
        url: "https://youtu.be/dSYnNtH_8LY"
      },
      date: "2019/8/8",
      duration: 1,
      authors: [
        "IBM Research"
      ]
    },
    {
      title: {
        text: "IBM Research AI Explainability 360 Toolkit",
        url: "https://www.youtube.com/watch?v=Yn4yduyoQh4"
      },
      date: "2019/9/5",
      duration: 36,
      authors: [
        "Vijay Arya",
        "Amit Dhurandhar"
      ]
    },
    {
      title: {
        text: "Explainability 360 Tutorial",
        url: "https://youtu.be/TGPHPCg_zKA"
      },
      date: "2019/9/18",
      duration: 83,
      authors: [
        "Amit Dhurandhar"
      ]
    },
    {
      title: {
        text: "One Explanation Doesn't Fit All: A Toolkit & Taxonomy of AI Explainability Techniques",
        url: "https://youtu.be/srfddGQbzJ8"
      },
      date: "2019/11/21",
      duration: 35,
      authors: [
        "Ronny Luss"
      ]
    },
    {
      title: {
        text: "AI Explainability 360 Tutorial at FAT*2020",
        url: "https://youtu.be/DUF7_HULaYs"
      },
      date: "2020/1/27",
      duration: 177,
      authors: [
        "Vijay Arya",
        "Amit Dhurandhar",
        "Dennis Wei"
      ]
    },
    {
      title: {
        text: "One Explanation Doesn't Fit All: A Toolkit & Taxonomy of AI Explainability Techniques",
        url: "https://www.youtube.com/watch?v=QYTlpkaAqiw"
      },
      date: "2020/3/3",
      duration: 55,
      authors: [
        "Ronny Luss"
      ]
    },
    {
      title: {
        text: "Explaining with AI Explainability 360",
        url: "https://youtu.be/lvbE_Piv-XQ"
      },
      date: "2020/4/3",
      duration: 69,
      authors: [
        "Dennis Wei"
      ]
    },
    {
      title: {
        text: "Model Explainability Forum",
        url: "https://youtu.be/B2QBnVnbt7A"
      },
      date: "2020/08/11",
      duration: 92,
      authors: ["Solon Barokas", "Rayid Ghani", "Alessya Labzhinova", "Hima Lakkaraju", "Kush R. Varshney"]
    }
  ],
  "Adversarial Robustness": [
    {
      title: {
        text: "IBM Adversarial Robustness Toolbox",
        url: "https://youtu.be/gguyhEwoecI"
      },
      date: "2018/8/10",
      duration: 3,
      authors: [
        "Mathieu Sinn",
        "Maria-Irina Nicolae",
        "Ian Molloy"
      ]
    },
    {
      title: {
        text: "Recent Progress in Adversarial Robustness of AI Models: Attacks, Defenses, and Certification,",
        url: "https://youtu.be/RYpmTldTkcw"
      },
      date: "2019/5/20",
      duration: 59,
      authors: [
        "Pin-Yu.Chen"
      ]
    },
    {
      title: {
        text: "Adversarial Robustness Toolbox\u00a0 How to attack and defend your machine learning models,",
        url: "https://youtu.be/tTN_BqTv440"
      },
      date: "2019/9/17",
      duration: 23,
      authors: [
        "Beat Buesser"
      ]
    },
    {
      title: {
        text: "ART 360: Defending AI models against adversarial attacks",
        url: "https://youtu.be/f1d658QSmv4"
      },
      date: "2019/10/31",
      duration: 36,
      authors: [
        "Mathieu Sinn"
      ]
    },
    {
      title: {
        text: "Towards robust and privacy preserving AI",
        url: "https://youtu.be/Y34VntIyJFk"
      },
      date: "2020/4/29",
      duration: 42,
      authors: [
        "Mathieu Sinn"
      ]
    },
    {
      title: {
        text: "Bridging Mode Connectivity in Loss Landscapes and Adversarial Robustness",
        url: "https://www.youtube.com/watch?v=GeI0_M8DsHE"
      },
      date: "2020/6/28",
      duration: 30,
      authors: [
        "Pin-Yu Chen"
      ]
    },
    {
      title: {
        text: "Adversarial Robustness of Deep Learning Models",
        url: "https://sites.google.com/umich.edu/eccv-2020-adv-robustness"
      },
      date: "2020/8/23",
      duration: 90,
      authors: [
        "Pin-Yu Chen"
      ]
    },
    {
      title: {
        text: "Adversarial Robustness of Deep Learning Models: Attack, Defense and Verification",
        url: "https://2020.ieeeicassp.org/program/tutorials/adversarial-robustness-of-deep-learning-models-attack-defense-and-verification/"
      },
      date: "2020/5/4",
      duration: 180,
      authors: [
        "Pin-Yu Chen"
      ]
    }
  ],

  "Science for Social Good": [
    {
      title: {
        text: "IBM Science for Social Good: Giving Back \u2014 with Science",
        url: "https://youtu.be/XCT0iv49Nn4"
      },
      date: "2019/7/26",
      duration: 2,
      authors: [
        "Saska Mojsilovic",
        "Kush R Varshney"
      ]
    },
    {
      title: {
        text: "Exploring AI for Social Good and COVID-19",
        url: "https://youtu.be/zaBGqjnOfNY"
      },
      date: "2020/6/4",
      duration: 19,
      authors: [
        "Saska Mojsilovic"
      ]
    }
  ],
  
  "HCI for AI": [
    {
      title: {
        text: "Questioning the AI: Informing Design Practices for Explainable AI User Experiences",
        url: "https://youtu.be/KSGQEvHeOuI"
      },
      date: "2020/6/16",
      duration: 14,
      authors: [
        "Vera Liao"
      ]
    }
  ]
}