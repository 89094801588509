import React from 'react';

import "./FactSheetHMDA.scss";
import { Components } from '../../api/factsheets-api';
import { renderValue, stringifiable, fromJson } from './RenderingHelpers';
import moment from 'moment';
import { FACT_FAIRNESS_MON_ENABLE_FAV_CLASSES, FACT_FAIRNESS_MON_ENABLE_UNFAV_CLASSES, FACT_FAIRNESS_OPENSCALE_METRICS, FACT_FAIRNESS_OPENSCALE_NUMRECORDS, FACT_FAIRNESS_OPENSCALE_THRESHOLD, FACT_PRIVILIEGED_GROUPS, FACT_UNPRIVILEGED_GROUPS, orderedStages } from './HMDAConstants';
import { Tooltip, Tag } from 'carbon-components-react';
import { keys, groupBy, mapValues, join, map, isNumber } from 'lodash';
import { FACT_NEW_DATASET_DISTRIBUTIONS, FACT_FEATURE_COLUMNS, FACT_FAIRNESS_COLUMNS, FACT_CATEGORICAL_COLUMNS, FACT_FAIRNESS_FEATURE_LIST, FACT_FAIRNESS_METRICS_AIF360, FACT_EXPLAINABILITY_METRICS_AIX360, FACT_ADVERSARIAL_ROBUSTNESS_METRICS_ART, FACT_QUALITY_METRICS, FACT_TRAINING_ACCURACY, FACT_TESTING_ACCURACY, FACT_PROTECTED_ATTR_NAMES } from './HMDAConstants';

export const FactSheetHMDA = ( props: { factsheet: Components.Schemas.FactSheet } ) => {
    const gfByStageByFtID = mapValues(groupBy(props.factsheet.facts, "value.stage"), f => groupBy(f, "factTypeId"))
    return <>
        <div className="bx--grid fs-hmda-container">
            <div className="bx--row">
                <div  className="bx--col-lg-16">
                    <div className="model-title"><h2>Mortgage Evaluator</h2></div>
                    <div className="model-ts"><h4>{moment.utc(props.factsheet.timestamp).format("M/D/YYYY LT ([GMT]Z)")}</h4></div>
                </div>
            </div>
            <div className="bx--row fs-hmda-content">
                <div  className="bx--col-lg-16 fs-hmda-content">
                    {
                        // Iterate through all the stages with one or more facts
                        orderedStages.filter( s => keys(gfByStageByFtID[s]).length > 0).map( stage => {
                            const groupedFacts = gfByStageByFtID[stage]
                            return <>
                                <table className="fs-hmda-table">
                                    <thead>
                                        <tr className="top-most-row"><td colSpan={2} >{stage}</td></tr>
                                    </thead>
                                    <tbody>
                                    {
                                        keys(groupedFacts).map( (ftid, ix) => <FactContentFull facts={groupedFacts[ftid]} ix={ix} key={`fact-content-${ix}`}/>)
                                    }
                                </tbody>
                                </table>
                            </>
                        })
                    }
                    <br/>
                </div>
            </div>
        </div>
    </>
}

const FactContentFull = ( props: { facts: Components.Schemas.Fact[], ix: number }) => {
    // we assume that if multiple facts come in the facts property, they will all
    // share the same type (maybe it's a strong assumption, but it'll have to do for now)
    const multipleFacts = props.facts.length > 1;
    const displayName = props.facts[0].displayName;
    // transform fact type information into a more suitable data structure
    const fact_to_type: { [indexer: string]: string } = {};
    props.facts[0].factType?.fields.filter(f => f.fieldName !== 'stage').forEach((ftrf) => {
        if (ftrf.fieldName) {
            fact_to_type[ftrf.fieldName] = ftrf.fieldType ?? "";
        }
    })

    let content : string = "";
    let comment : string = "N/A";
    let stage : string = "N/A";
    const fieldNames = Object.keys(fact_to_type);
    if (!multipleFacts) {
        const fact = props.facts[0];
        stage = fact.value.stage ?? "N/A";
        comment = fact.comment ?? "N/A";
        if (hasCustomRenderer(fact)) {
            content = renderHMDAFact(fact);
        } else {
            if (fieldNames.length > 1) {
                content = fieldNames.reduce((t, k) => {
                    return t + `|${k}|${renderValueAsString(k, fact_to_type[k], fact.value[k])}|\n`
                }, "|Field|Value|\n|---|---|\n");
            } else {
                const k = fieldNames[0];
                content = renderValueAsString(k, fact_to_type[k], fact.value[k])
            }
        }
    }
    // else
    // {
    //     // visualize as a table, with as many columns as facts we have for this specific type
    //     const rows : string[][] = [
    //         ['Field'].concat(props.facts.map( f => { return ( f.modelId in columnTexts ? columnTexts[f.modelId] : 'Value') })),
    //         //['Field'].concat(Array.from({length: props.facts.length}).map( x => 'Value')),
    //         Array.from({length:props.facts.length+1}).map(x => '---')
    //     ];

    //     fieldNames.forEach( (fn) => {
    //         const row : string[] = [ fn ];
    //         props.facts.forEach( f => {
    //             row.push(f.value[fn])
    //         })
    //         rows.push(row)
    //     })

    //     content = rows.map( r => "|" + r.join("|") + "|").join("\n")
    // }


    const toRender = content;

    return <>
        <tr className={props.ix === 0 ? "top-most-row" : "" }>
            <td className="fact">
                <Tooltip triggerClassName="term-definition" triggerText={displayName} showIcon={false}>
                    {comment}<br/>
                    <Tag type="blue">{stage}</Tag>
                </Tooltip>
            </td>
            <td className="value">
                {renderValue("markdown", toRender)}
            </td>
        </tr>
    </>
}

const renderValueAsString = (key: string, type: string, value: any) => {
    // console.log(`Rendering: key: ${key}, type: ${type}, value: ${value}`)
    let content : string = "";
    if (stringifiable.includes(type) || type === "markdown") {
        content = `${value}`;
        if (type === "string") content = content.replace(/_/g, "\\_");
    } else if (fromJson.includes(type)) {
        content = "```json\n" + JSON.stringify(value, null, 2) +  "\n```\n";
    } else if (type === "table") {
        const table = value as string[][];
        content = table.toString();
    }
    return content;
}

//------- Specific HMDA RenderingHelpers

type HMDARenderingFunction = (value: any) => string;

function formatMetric(value: any) {
    if (value === null) {
        return "N/A"
    } else if (isNumber(value) && !Number.isInteger(value)) {
        return value.toFixed(2);
    }

    return value;
}

export function hasCustomRenderer(fact: Components.Schemas.Fact) {
    return fact.factTypeId in HMDARenderingMap;
}

export function renderHMDAFact(fact: Components.Schemas.Fact) {
    return HMDARenderingMap[fact.factTypeId](fact.value);
}

function columnNamesToTable(value: any, propName: string) {
    let columnNamesRows = ["|Column Name|","|---|"];
    // value.column_names.map( (name: string) => columnNamesRows.push(`|\`${name}\`|`));
    value[propName].map( (name: string) => columnNamesRows.push(`|${name.replace(/_/g, "\\_")}|`));
    return join(columnNamesRows, "\n");
}

function metricsToTable( metrics: {name: string, value: any}[]) {
    const metricsRows = ["|Metric|Value|","|---|---|"];
    metrics.map( m => metricsRows.push(`|${m.name}|${formatMetric(m.value)}|`))
    return join(metricsRows, "\n");
}

function accuracyToPercentage( val: any) {
    return `${(val.accuracy_value * 100).toFixed(2)} %`;
}

type FairnessMetricsFact = { [pa: string]: { disparate_impact: number, statistical_parity_difference: number } }
function renderFairnessMetrics(metrics: any) {
    const metricsRows = ["|Protected Attribute|Disparate Impact|Statistical Parity Difference|","|---|---|---|"];
    map( metrics as FairnessMetricsFact, (m, pa)  => {
        metricsRows.push(`|\`${pa}\`|${formatMetric(m.disparate_impact)}|${formatMetric(m.statistical_parity_difference)}|`)
    })

    return join(metricsRows, "\n");
}


// - OpenScale related facts
function renderOpenScaleMetrics(osMetrics: any) {
    const metricsRows = ["|Protected Attribute|Disparate Impact|","|---|---|"];
    console.log(osMetrics);
    map( osMetrics, m  => {
        metricsRows.push(`|\`${m.feature}\`|${formatMetric(m.fairness_threshold)}|`)
    })

    return join(metricsRows, "\n");
}


function renderFairnessRelatedSingleFact( osFact: any ) {
    const metricsRows = ["|Protected Attribute|Value|", "|---|---|"];
    Object.keys(osFact).forEach( pa => {
        metricsRows.push(`|\`${pa}\`|${osFact[pa]}|`)
    })

    return join(metricsRows, "\n");
}

// The following map has a specific rendering function for the FactTypeIds that need one.
const HMDARenderingMap : { [ftid: string]: HMDARenderingFunction} = {
    [ FACT_NEW_DATASET_DISTRIBUTIONS ]: (value) => {
        const purposes = ["train", "test", "prod"];
        const datasetDistributionRows = ["|Purpose|Ratio|","|---|---|"];
        purposes.map(p => datasetDistributionRows.push(`|${p}|${(value.distributions_ratio[p] * 100).toFixed(0)} %`))
        return join(datasetDistributionRows, "\n");
    },
    [ FACT_FEATURE_COLUMNS ]: (value) => columnNamesToTable(value, "column_names"),
    [ FACT_FAIRNESS_COLUMNS ]: (value) => columnNamesToTable(value, "column_names"),
    [ FACT_CATEGORICAL_COLUMNS ]: (value) => columnNamesToTable(value, "column_names"),
    [ FACT_FAIRNESS_FEATURE_LIST ]: (value) => { //Fairness Feature List
        const fflr = ["|Feature|Majority|Minority|Threshold|","|---|---|---|---|"];
        value.range_of_feature_values.map( (elem: any) =>
            fflr.push(`|\`${elem.feature}\`|\`${elem.majority}\`|\`${elem.minority}\`|${elem.threshold}|`)
        )
        return join(fflr, "\n");
    },
    [ FACT_FAIRNESS_METRICS_AIF360 ]: (value) => { //Fairness metrics (AIF360)
        return renderFairnessMetrics(value.metrics);
    },
    [ FACT_EXPLAINABILITY_METRICS_AIX360 ]: (value) => { //Explainability metrics (AIX360)
        return metricsToTable([
            { name: "Faithfulness Mean", value: value.metrics.faithfulness_mean },
            { name: "Faithfulness Standard Deviation", value: value.metrics.faithfulness_std }
        ])
    },
    [ FACT_ADVERSARIAL_ROBUSTNESS_METRICS_ART ]: (value) => { //Adversarial robustness metrics (ART)
        return metricsToTable([
            { name: "Empirical Robustsness", value: value.metrics.empirical_robustness },
        ])
    },
    [ FACT_QUALITY_METRICS ]: (value) => { //Quality metrics
        return metricsToTable([
            { name: "Accuracy", value: value.metrics.accuracy },
            { name: "Area under PR", value: value.metrics.area_under_pr },
            { name: "Area under ROC", value: value.metrics.area_under_roc },
            { name: "F1", value: value.metrics.f1_measure },
            { name: "Logarithmic loss", value: value.metrics.log_loss },
            { name: "Precision", value: value.metrics.precision },
            { name: "Recall", value: value.metrics.recall },
            { name: "True Positive Rate", value: value.metrics.true_positive_rate },
            { name: "False Positive Rate", value: value.metrics.false_positive_rate },
        ])
    },
    [ FACT_TRAINING_ACCURACY ]: accuracyToPercentage,
    [ FACT_TESTING_ACCURACY ]: accuracyToPercentage,
    [ FACT_PROTECTED_ATTR_NAMES ]: (value) => columnNamesToTable(value, "names"),
    [ FACT_FAIRNESS_OPENSCALE_THRESHOLD ] : (value) => renderFairnessRelatedSingleFact(value.threshold_value),
    [ FACT_FAIRNESS_OPENSCALE_NUMRECORDS ] : (value) => renderFairnessRelatedSingleFact(value.number_of_records),
    [ FACT_FAIRNESS_OPENSCALE_METRICS ] : (value) => renderOpenScaleMetrics(value.matrics[0].metrics[0].value.metrics),
    [ FACT_PRIVILIEGED_GROUPS ] : (value) => renderFairnessRelatedSingleFact(value["privileged groups"]),
    [ FACT_UNPRIVILEGED_GROUPS ] : (value) => renderFairnessRelatedSingleFact(value["unprivileged groups"]),
    [ FACT_FAIRNESS_MON_ENABLE_FAV_CLASSES ] : (value) => renderFairnessRelatedSingleFact(value.classes),
    [ FACT_FAIRNESS_MON_ENABLE_UNFAV_CLASSES ] : (value) => renderFairnessRelatedSingleFact(value.classes),

}
