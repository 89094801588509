import React, { Fragment } from 'react';
import { Components } from '../../api/factsheets-api';
import { renderValue, makeReadable } from './RenderingHelpers';
import Carousel from "react-multi-carousel";

import "./FactSheetSlideShow.scss";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const FactSheetSlideShow = ( props: { factsheet: Components.Schemas.FactSheet } ) => (
    <>
    <div className="bx--grid">
        <section className="bx--row">
            <div className="fs-slideshow-container">
                <Carousel responsive={responsive} showDots={true} renderDotsOutside={true}>
                    <div className="slide title">
                        <div className='box black'>{makeReadable(props.factsheet.modelId)}</div><div className='box empty white'></div>
                    </div>

                    {  props.factsheet.facts.map((fact, index) => (<FactSlide fact={fact} key={`fact-slide-${index}`}/>) )}

                    <div className="slide thanks">Thank You.</div>
                </Carousel>
            </div>
        </section>
    </div>
    </>
);

const FactSlide = ( props: { fact: Components.Schemas.Fact }) => {
    // transform fact type information into a more suitable data structure
    let fact_to_type: { [indexer: string]: string } = {};
    props.fact.factType?.fields.forEach((ftrf) => {
        if (ftrf.fieldName) {
            fact_to_type[ftrf.fieldName] = ftrf.fieldType ?? "";
        }
    })

    return <>
        <div className="slide fact">
            <div className='box grey'>{props.fact.displayName}</div>
            <div className='box white'>
                { Object.keys(props.fact.value).filter(filterSlide).map((k) => <Fragment key={k}>{ renderValue(fact_to_type[k], props.fact.value[k])}</Fragment>) }
            </div>
        </div>
    </>
}

function filterSlide(fieldName: string): boolean {
    return fieldName.endsWith("_slide");
}

// function downloadPPTX(factsheet: Components.Schemas.FactSheet) {
//     console.log(factsheet);
//     fetch('http://localhost:3210/fs-to-pptx', {
//         method: 'post',
//         headers: {'Content-Type': 'application/json'},
//         body: JSON.stringify(factsheet)
//     })
//     .then((response) => response.blob() )
//     .then((blob) => {
//         // 2. Create blob link to download
//         const url = window.URL.createObjectURL(new Blob([blob]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', `factsheet-slideshow.pptx`);
//         // 3. Append to html page
//         document.body.appendChild(link);
//         // 4. Force download
//         link.click();
//         // 5. Clean up and remove the link
//         link.parentNode?.removeChild(link);
//     })
//     .catch((error) => {
//         error.json().then((json: any) => {
//             console.log(json);
//         })
//     });
// }