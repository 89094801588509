import React from 'react';
import { Loading } from 'carbon-components-react';
import { FactSheetLongCatalog } from './FactSheetLongCatalog';
import { Components } from '../../api/factsheets-api';
// import { DemoStepTitle } from '../DemoStepTitle/DemoStepTitle';
import { FactSheetShortReport } from './FactSheetShortReport';
import { FactSheetSlideShow } from './FactSheetSlideShow';
import { rationales } from '../../assets/rationale';

type FactSheetParams = {
    presentation: "web" | "datasheet" | "slides",
    factsheet?: Components.Schemas.FactSheet
}

export const FactSheet = ( props: FactSheetParams ) => {
    let content: JSX.Element;
    if (props.factsheet && Object.keys(props.factsheet).length > 0) {
        if (props.presentation === "web") {
            content = <FactSheetLongCatalog factsheet={props.factsheet} rationale={props.factsheet.modelId in rationales ? rationales[props.factsheet.modelId] : {}}/>
        } else if (props.presentation === "datasheet") {
            content = <FactSheetShortReport factsheet={props.factsheet}/>
        } else if (props.presentation === "slides") {
            content = <FactSheetSlideShow factsheet={props.factsheet}/>
        } else {
            content = <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
                <section className="bx--row">
                    <div  className="bx--col-lg-16">
                        <br/><br/><br/>
                        <h1 style={{textAlign: "center", fontSize: 50}}>
                            Sorry, the selected presentation format is not supported... yet...
                        </h1>
                    </div>
                </section>
            </div>
        }
    } else {
        content = <Loading description="Fetching FactSheet..."/>
    }

    return content;
}
