import React from 'react';
import { SimpleContentPage } from '../../components';

import './OurPapers.css';
import { renderOwnResearchPapers } from '../../assets/papers';

export const Papers = () => (
    <SimpleContentPage key="Papers" title="Our Papers">
        <h3>IBM Research papers about FactSheets and AI Governance</h3><br/>
        <div className="div-as-paragraph our-papers-container">{ renderOwnResearchPapers() }</div>
    </SimpleContentPage>
)