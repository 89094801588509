import React from "react";
import { Link } from "carbon-components-react";

type Paper = {
    title: string,
    url: string,
    authors: string,
    publication?: string,
    date: string,
    other_appearances?: string,
    other_url?: string,
    other_date?: string,
    our_paper?: boolean,
}

export const researchPapers : Paper[] = [
    {
        title: "Datasheets for Datasets",
        url: "https://cacm.acm.org/magazines/2021/12/256932-datasheets-for-datasets/fulltext",
        authors: "Gebru, T., Morgenstern, J., Vecchione, B., Vaughan, J.W., Wallach, H., Daumeé III, H. and Crawford, K.",
        publication: "Communications of the ACM",
        date: "Dec, 2021",
        other_appearances: "arXiv",
        other_url: "https://arxiv.org/pdf/1803.09010.pdf",
        other_date: "May, 2018",
        our_paper: false,
    },
    {
        title: "The Dataset Nutrition Label: A Framework to Drive Higher Quality Data Standards",
        url: "https://arxiv.org/ftp/arxiv/papers/1805/1805.03677.pdf",
        authors: "Holland, S., Hosny, A., Newman, S., Joseph, J. and Chmielinski, K.",
        publication: "arXiv",
        date: "May, 2018",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: false,
    },
    {
        title: "Of Oaths and Checklists",
        url: "https://www.oreilly.com/ideas/of-oaths-and-checklists",
        authors: "Loukides, M., Mason, H. and Patil, D.J.",
        publication: "Ethics and Data Science",
        date: "July, 2018",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: false,
    },
    {
        title: "FactSheets: Increasing Trust in AI Services through Supplier's Declarations of Conformity",
        url: "https://ieeexplore.ieee.org/document/8843893",
        authors: "Arnold, M., Bellamy, R.K.E., Hind, M., Houde, S., Mehta, S., Mojsilović, A. ,Nair, R., Natesan Ramamurthy, K., Reimer, D., Olteanu, A., Piorkowski, D., Tsay, J. and Varshney, K. R.",
        publication: "IBM Journal of Research and Development, 63(4/5)",
        date: "July-Sept, 2019",
        other_appearances: "first posted on arXiv",
        other_url: "https://arxiv.org/pdf/1808.07261.pdf",
        other_date: "Aug, 2018",
        our_paper: true,
    },
    {
        title: "Model Cards for Model Reporting",
        url: "https://dl.acm.org/doi/10.1145/3287560.3287596",
        authors: "Mitchell, M., Wu, S., Zaldivar, A., Barnes, P., Vasserman, L., Hutchinson, B., Spitzer, E., Raji, I.D. and Gebru, T.",
        publication: "ACM FAT* 2019 conference",
        date: "Jan, 2019",
        other_appearances: "first posted on arXiv",
        other_url: "https://arxiv.org/pdf/1810.03993.pdf",
        other_date: "Oct, 2018",
        our_paper: false,
    },
    {
        title: "Data Statements for Natural Language Processing: Toward Mitigating System Bias and Enabling Better Science",
        url: "https://openreview.net/pdf?id=By4oPeX9f",
        authors: "Bender, E. and Friedman, B.",
        publication: "OpenReview",
        date: "Oct, 2018",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: false,
    },
    {
        title: "Why We Need to Audit Algorithms",
        url: "https://hbr.org/2018/11/why-we-need-to-audit-algorithms",
        authors: "Guszcza, J., Rahwan, I., Bible, W., Cebrian, M. and Katyal, V.",
        publication: "Harvard Business Review",
        date: "Nov, 2018",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: false,
    },
    {
        title: "Translation, Tracks & Data: an Algorithmic Bias Effort in Practice",
        url: "https://dl.acm.org/doi/10.1145/3290607.3299057",
        authors: "Cramer, H., Garcia-Gathright, J., Reddy, S., Springer, A., and Takeo Bouyer, R.",
        publication: "ACM CHI'19 Conference on Human Factors in Computing Systems",
        date: "May, 2019",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: false,
    },
    {
        title: "Canada Protocol: an ethical checklist for the use of Artificial Intelligence in Suicide Prevention and Mental Health",
        url: "https://arxiv.org/ftp/arxiv/papers/1907/1907.07493.pdf",
        authors: "Mörch, C-M., Gupta, A., Mishara, B.L.",
        publication: "arXiv",
        date: "Jul, 2019",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: false,
    },
    {
        title: "Experiences with Improving the Transparency of AI Models and Services",
        url: "https://dl.acm.org/doi/10.1145/3334480.3383051?cid=81339504521",
        authors: "Hind, M., Houde, S., Martino, J., Mojsilovic, A., Piorkowski, D., Richards, J., Varshney, K.R.",
        publication: "ACM CHI'20 Conference on Human Factors in Computing Systems, Late-Breaking Work",
        date: "Apr, 2020",
        other_appearances: "first posted on arXiv",
        other_url: "https://arxiv.org/pdf/1911.08293.pdf",
        other_date: "Nov, 2019",
        our_paper: true,
    },
    {
        title: "ABOUT ML: Annotation and Benchmarking onUnderstanding and Transparency of MachineLearning Lifecycles",
        url: "https://arxiv.org/pdf/1912.06166.pdf",
        authors: "Deborah I. Raji and Jingying Yang",
        publication: "arXiv",
        date: "Jan, 2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
    },
    {
        title: "Co-Designing Checklists to Understand Organizational Challenges and Opportunities around Fairness in AI",
        url: "https://dl.acm.org/doi/abs/10.1145/3313831.3376445",
        authors: "Madaio, M.A., Wortman Vaughan, J., Stark, L., Wallach, H.",
        publication: "ACM CHI'20 Conference on Human Factors in Computing Systems",
        date: "Apr, 2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: false,
    },
    {
        title: "A Human-Centered Methodology for Creating AI FactSheets",
        url: "http://sites.computer.org/debull/A21dec/p47.pdf",
        authors: "Richards, J., Piorkowski, D., Hind, M., Houde, S., Mojsilović, A., Varshney, K.",
        publication: "Bulletin of the Technical Committee on Data Engineering",
        date: "December, 2021",
        other_appearances: "arXiv",
        other_url: "https://arxiv.org/pdf/2006.13796.pdf",
        other_date: "June, 2020",
        our_paper: true,
    },
    {
        title: "Towards evaluating and eliciting high-quality documentation for intelligent systems",
        url: "https://arxiv.org/pdf/2011.08774.pdf",
        authors: "Piorkowski, D., González, D., Richards, J., Houde, S.",
        publication: "arXiv",
        date: "Nov, 2020",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "How AI Developers Overcome Communication Challenges in a Multidisciplinary Team: A Case Study",
        url: "https://arxiv.org/pdf/2101.06098",
        authors: "Piorkowski, D., Park, S., Wang, A.Y., Wang, D., Muller, M., Portnoy, F.",
        publication: "arXiv",
        date: "Jan, 2021",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "The Sanction of Authority: Promoting Public Trust in AI",
        url: "https://arxiv.org/abs/2102.04221",
        authors: "Knowles, B., Richards, J.",
        publication: "arXiv",
        date: "Jan, 2021",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Accountable Federated Machine Learning in Government: Engineering and Management Insights",
        url: "https://link.springer.com/chapter/10.1007/978-3-030-82824-0_10",
        authors: "Balta, D., Sellami, M., Kuhn, P., Schöpp, U., Buchinger, M., Baracaldo, N., Anwar, A., Ludwig, H., Sinn, M., Purcell, M., Altakrouri, B.",
        publication: "International Conference on Electronic Participation",
        date: "Aug, 2021",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
      title: "The Dataset Nutrition Label (2nd Gen): Leveraging Context to Mitigate Harms in Artificial Intelligence ",
      url: "https://arxiv.org/abs/2201.03954",
      authors: "Chmielinski, K. S., Newman, S., Taylor, M., Joseph, J., Thomas, K., Yurkofsky, J., Qiu, Y. C.",
      publication: "arXiv",
      date: "Jan, 2022",
      other_appearances: "",
      other_url: "",
      other_date: "",
      our_paper: false,
    },
    {
        title: "Evaluating a Methodology for Increasing AI Transparency: A Case Study",
        url: "https://arxiv.org/pdf/2201.13224.pdf",
        authors: "Piorkowski, D., Richards, J., Hind, M.",
        publication: "arXiv",
        date: "Jan, 2022",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
        title: "Towards an Accountable and Reproducible Federated Learning: A FactSheets Approach",
        url: "https://arxiv.org/pdf/2202.12443",
        authors: "Baracaldo, N., Anwar, A., Purcell, M., Rawat, A., Sinn, M., Altakrouri, B., Balta, D., Sellami, M., Kuhn, P., Schopp, U., Buchinger, M.",
        publication: "arXiv",
        date: "Feb, 2022",
        other_appearances: "",
        other_url: "",
        other_date: "",
        our_paper: true,
    },
    {
      title: "Quantitative AI Risk Assessments: Opportunities and Challenges",
      url: "https://arxiv.org/abs/2209.06317",
      authors: "Piorkowski, D., Hind, M., Richards, J.",
      publication: "arXiv",
      date: "Jan, 2023",
      other_appearances: "",
      other_url: "",
      other_date: "",
      our_paper: true,
    },
];

const renderPapers = (papers: Paper[]) => (
    <ul>
     {
     papers.map( (paper) => (
        <li className="research-paper" key={paper.title}>
            <Link href={paper.url} target="_blank"><strong>{paper.title}, </strong></Link>
            {paper.authors}
            { paper.publication && <>, {paper.publication}</>}
            { paper.date && <>, {paper.date}</>}
            { paper.other_appearances && <>, <Link href={paper.other_url} target="_blank">{paper.other_appearances}</Link></>}
            { paper.other_date && <>, {paper.other_date}</>}
        </li>
    ))}
    </ul>
)

export const renderAllResearchPapers = () => (
    renderPapers(researchPapers)
)

export const renderOwnResearchPapers = () => (
    renderPapers(researchPapers.filter( rp => rp.our_paper ))
)