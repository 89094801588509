import React, { Fragment } from 'react';
import { SimpleContentPage, slugify } from '../../components';
import { Link } from 'carbon-components-react';

import './RelatedWork.css';
import { renderAllResearchPapers } from '../../assets/papers';
import { NavLink } from 'react-router-dom';

const documentationGuidelines = [
    {
        name: "EU Ethics Guidelines for Trustworthy AI (Apr, 2019)",
        url: "https://digital-strategy.ec.europa.eu/en/library/ethics-guidelines-trustworthy-ai",
        comments: "European Union"
    },
    {
        name: "TM Forum AI Checklist",
        url: "https://www.tmforum.org/resources/how-to-guide/ig1199-ai-da-management-standards-introductory-guide-ai-checklists-v1-0/",
        comments: ""
    },
    {
        name: "TM Forum AI Model Data Sheets",
        url: "https://www.tmforum.org/resources/specification/ig1232-ai-model-data-sheet-specification-v1-0-0/",
        comments: ""
    },
    {
        name: "Parnership for AI ABOUT ML Project",
        url: "https://www.partnershiponai.org/about-ml/",
        comments: ""
    },
    {
        name: "Johns Hopkins Center for Government Excellence, Ethics & Algorithms Toolkit",
        url: "http://ethicstoolkit.ai/",
        comments: ""
    },
    {
        name: "Center for Democracy and Technology, Digital Decisions Tool",
        url: "https://cdt.org/blog/digital-decisions-tool/",
        comments: ""
    },
    {
        name: "Machine Intelligence, AI Ethics Framework",
        url: "https://www.migarage.ai/ethics-framework/",
        comments: ""
    },
    {
        name: "US Federal Trade Commission: Aiming for truth, fairness, and equity in your company’s use of AI (Apr, 2021)",
        url: "https://www.ftc.gov/news-events/blogs/business-blog/2021/04/aiming-truth-fairness-equity-your-companys-use-ai",
        comments: ""
    },
    {
        name: "UK Government Algorithm Transparency Standard (Nov, 2021)",
        url: "https://www.gov.uk/government/news/uk-government-publishes-pioneering-standard-for-algorithmic-transparency",
        comments: ""
    },
    {
        name: "US White House Effort to Create a Bill of Rights for an Automated Society (Nov, 2021)",
        url: "https://www.whitehouse.gov/ostp/news-updates/2021/11/10/join-the-effort-to-create-a-bill-of-rights-for-an-automated-society/",
        comments: ""
    },
    {
        name: "NYC regulation for transparency of 3rd party audits for Automated Employment Decision Tools (Dec, 2021)",
        url: "https://legistar.council.nyc.gov/LegislationDetail.aspx?ID=4344524&GUID=B051915D-A9AC-451E-81F8-6596032FA3F9",
        comments: ""
    },
];

const policyGuidelines = [
    {
        name: "IBM Policy Lab — Precision Regulation for Artificial Intelligence",
        url: "https://www.ibm.com/blogs/policy/ai-precision-regulation/"
    },
    {
        name: "BSA | The Software Alliance — Five Key Pillars for Responsible Artificial Intelligence",
        url: "https://ai.bsa.org/"
    },
    {
        name: "U.S. Chamber of Commerce’s Technology Engagement Center — Artificial Intelligence Principles",
        url: "https://americaninnovators.com/news/u-s-chamber-releases-artificial-intelligence-principles/"
    },
    {
        name: "ITI — AI Policy Principles",
        url: "https://www.itic.org/resources/AI-Policy-Principles-FullReport2.pdf"
    },
    {
        name: "CIPL — Artificial Intelligence and Data Protection:Delivering Sustainable AI Accountability in Practice - Hard Issues and Practical Solutions",
        url: "https://www.informationpolicycentre.com/uploads/5/7/1/0/57104281/cipl_second_report_-_artificial_intelligence_and_data_protection_-_hard_issues_and_practical_solutions__27_february_2020_.pdf"
    },
    {
        name: "OECD Principles on Artificial Intelligence",
        url: "https://www.oecd.org/going-digital/ai/principles/"
    },
]

const sections = [
    {
        title: "Research papers (in chronological order)",
        content: <div className="related-work-papers">{ renderAllResearchPapers() }</div>
    },
    {
        title: "Transparent Documentation Guidelines and Regulations",
        content: <ul className="related-work-guidelines">
        {
            documentationGuidelines.map( (g) => (
                <li className="related-work-guideline" key={g.name}>
                    <Link href={g.url} target="_blank"><strong>{g.name}</strong></Link>
                    { g.comments && <>, {g.comments}</>}
                </li>
            ))
        }
        </ul>
    },
    {
        title: "AI Policy Guidelines",
        content: <ul className="related-work-guidelines">
        {
            policyGuidelines.map( (g) => (
                <li className="related-work-guideline" key={g.name}>
                    <Link href={g.url} target="_blank"><strong>{g.name}</strong></Link>
                </li>
            ))
        }
        </ul>
    },
    {
        title: "Research sites",
        content: <ul>
        <li><NavLink to="/" target="_blank"><strong>IBM AI FactSheets 360</strong></NavLink></li>
        <li><Link href="https://modelcards.withgoogle.com/about" target="_blank"><strong>Model Cards</strong></Link>, Google</li>
        </ul>
    },
]

export const RelatedWork = () => (
    <SimpleContentPage key="RelatedWork" title="Related Work">
        <p>
            Several other companies, organizations, and universities are working towards increasingly standardized ways of documenting AI models and services. Links to this work are provided here.
        </p><br/>
        <div className="related-work-container">
        {
            sections.map( (section) => (
                <Fragment key={section.title}>
                    <h3 id={slugify(section.title)}>{section.title}</h3><br/>
                    <div className="div-as-paragraph">{section.content}</div><br/><br/>
                </Fragment>
            ))
        }
        </div>
    </SimpleContentPage>
)