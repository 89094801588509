import React, { useState } from 'react';

import './Example.scss';
import { useFactSheet } from '../../components';
import { TileGroup, RadioTile, Tag, TooltipIcon, Loading } from 'carbon-components-react';

import { ExampleProps } from './utils';
import { useLocation } from 'react-router-dom';
import { getTagDescription } from '../../assets/tags';

import { ReactComponent as AllFactsLogo } from '../../assets/personas/AllFacts.svg'
import { ReactComponent as BusinessOwnerLogo } from '../../assets/personas/BusinessOwner.svg'
import { ReactComponent as DataScientistLogo } from '../../assets/personas/DataScientist.svg'
import { ReactComponent as ValidatorLogo } from '../../assets/personas/Validator.svg'
import { ReactComponent as AIOpsEngLogo } from '../../assets/personas/AIOpsEng.svg'
import { FactSheetHMDA } from '../../components/FactSheet/FactSheetHMDA';
import { clone, keys, find } from 'lodash';
import { PERSONA_FILTER_BUSINESS_OWNER, PERSONA_FILTER_DATA_SCIENTIST, PERSONA_FILTER_MODEL_VALIDATOR, PERSONA_FILTER_AI_OPS_ENGINEER, PersonaFactFilter } from '../../components/FactSheet/HMDAConstants';


type PersonaId = 'all'|'businessOwner'|'dataScientist'|'modelValidator'|'aiOpsEngineer';

type PersonaProps = {
    id: PersonaId,
    text: string,
    description: string,
    logo: React.FC,
    // Since the same ID could appear in multiple stages, the filtes is a list of IDs and a
    // list of stages to pinpoint particular facts
    filter: PersonaFactFilter;
}

const presentationOptions: PersonaProps[] = [
    {
        id: "all",
        text: "All Facts View",
        description: "Every fact collected from concept to deployment",
        logo: AllFactsLogo,
        filter: []
    },
    {
        id: "businessOwner",
        text: "Business Owner's View",
        description: "Filtered to show just business relevant facts",
        logo: BusinessOwnerLogo,
        filter: PERSONA_FILTER_BUSINESS_OWNER
    },
    {
        id: "dataScientist",
        text: "Data Scientist's View",
        description: "Primarily data and model metrics",
        logo: DataScientistLogo,
        filter: PERSONA_FILTER_DATA_SCIENTIST
    },
    {
        id: "modelValidator",
        text: "Model Validator's View",
        description: "Compares challenge model metrics",
        logo: ValidatorLogo,
        filter: PERSONA_FILTER_MODEL_VALIDATOR
    },
    {
        id: "aiOpsEngineer",
        text: "AI Ops Engineer's View",
        description: "Compares deployment metrics",
        logo: AIOpsEngLogo,
        filter: PERSONA_FILTER_AI_OPS_ENGINEER
    }
]

const getDefaultPersona = (hash: string) : PersonaId => {
    let ret: PersonaId = "all";
    if (hash === "businessOwner") return "businessOwner"
    if (hash === "dataScientist") return "dataScientist"
    if (hash === "modelValidator") return "modelValidator"
    if (hash === "aiOpsEngineer") return "aiOpsEngineer"
    return ret;
}

export const MortgageExample: React.FC<ExampleProps> = ( {model_id, model_name, model_url, model_tags} ) => {
    const location = useLocation();
    const factSheet = useFactSheet(model_id);
    const [persona, setPersona] = useState(getDefaultPersona(location.hash.replace("#", "")));

    return <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
        <section className="bx--row example-top">
            <div className="example-title-wrapper">
                <div className="logo-title">
                    {`${model_name} FactSheet`}
                </div>
                <div className="logo-subtitle">
                    Created to demonstrate how development and deployment facts of a mortgage evaluation model can be recorded and viewed
                </div>
                <div className="tags">
                    { model_tags.map( (t, ix) => (
                        <TooltipIcon tooltipText={getTagDescription(t)} align={model_tags.length -1 === ix ? "end" : (0 === ix ? "start" : "center")}>
                            <Tag key={t.text} type={t.color}>{t.text}</Tag>
                        </TooltipIcon>
                        )
                    )}
                </div>
            </div>
        </section>
        <hr id='content-separator'/>
        <section className="bx--row grey">
            <div className="example-content-wrapper">
                <div className="presentation">
                    <TileGroup
                        className="presentation-chooser-group"
                        defaultSelected={persona}
                        name="Presentations"
                        onChange={(value) => setPersona(value as PersonaId)}>
                        { presentationOptions.map( (tile, index) => (
                            <RadioTile className="presentation-rectangular" value={tile.id} id={`tile-${index}`} key={`tile-${index}`}>
                                <div className="presentation-full-tile">
                                    <div className="presentation-tile-image">
                                        <tile.logo/>
                                    </div>

                                    <div className="presentation-tile-text">
                                        <div className="presentation-tile-text-title">{tile.text}</div>
                                        <div className="presentation-tile-text-description">{tile.description}</div>
                                    </div>
                                </div>
                            </RadioTile>
                        ) )}

                    </TileGroup>
                </div>
                <div id="factsheet" className="factsheet">
                    <style>
                    {
                        `@media print {
                            body * {
                                margin: 0;
                                visibility: hidden;
                            }
                            #factsheet, #factsheet * {
                                visibility: visible;
                            }
                            #factsheet {
                                margin: 0;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }

                            #rationale-toggle-container *, #toc *, #toc-collapsed * {
                                display: none;
                            }
                        }`
                    }
                    </style>
                    {renderFactSheet(factSheet, persona)}
                </div>
            </div>
          </section>
    </div>
}

function renderFactSheet(factsheet: any, personaId: PersonaId): React.ReactNode {
    if (factsheet && keys(factsheet).length > 0) {
        const mergedFactsheet = clone(factsheet);
        const persona = find(presentationOptions, ['id', personaId]);
        if (persona.filter.length > 0) {
            mergedFactsheet.facts = mergedFactsheet.facts.filter( fact => persona.filter.some( f => f.ftid === fact.factTypeId && f.stages.includes(fact.value.stage)) );
        }
        return <FactSheetHMDA factsheet={mergedFactsheet} />
    } else {
        return <Loading description="Fetching FactSheet..."/>
    }
}
