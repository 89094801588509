import React from 'react';
import { SimpleContentPage } from '../../components';
import { ArrowRight16 } from '@carbon/icons-react';
import { NavLink } from 'react-router-dom';

import './ExamplesOverview.css';
import { Tag, TagTypeName } from 'carbon-components-react';
import { examplesInfoModelMarketPlace, examplesInfoGovernance, examplesInfoModelRisk } from '../../assets/examples';
import { availableTags } from '../../assets/tags';

export const ExamplesOverview = () => (
    <SimpleContentPage key="ExamplesOverview" title="FactSheet Examples Overview">
        <section className="example-text-section">
            <p>
            FactSheets are designed for specific use cases and audiences. The example use cases below highlight how FactSheets can differ both in terms of content and presentation and are grouped accordingly. Tags highlight FactSheet features that may be of interest. <em>A key to all tags is below.</em>
            </p>
        </section>
        <section className="example-box-section">
            <h3>Model Marketplace Examples</h3><br/>
            <div className="examples-container">
                {
                    examplesInfoModelMarketPlace.map( (ex) => (
                        <ExampleCard key={ex.id} title={ex.text} href={`/examples/${ex.id}`} description={ex.description} tags={ex.tags}/>
                    ))
                }
            </div>
        </section>
        <section className="example-box-section">
            <h3>Goveranance Examples</h3><br/>
            <div className="examples-container">
                {
                    examplesInfoGovernance.map( (ex) => (
                        <ExampleCard key={ex.id} title={ex.text} href={`/examples/${ex.id}`} description={ex.description} tags={ex.tags}/>
                    ))
                }
            </div>
        </section>
        <section className="example-box-section">
            <h3>Model Risk Examples</h3><br/>
            <div className="examples-container">
                {
                    examplesInfoModelRisk.map( (ex) => (
                        <ExampleCard key={ex.id} title={ex.text} href={`/examples/${ex.id}`} description={ex.description} tags={ex.tags}/>
                    ))
                }
            </div>
            <br/>
            {/* <div className="div-as-paragraph">
                <em>More examples coming soon</em>
            </div> */}
        </section>
        <br/>
        <section className="tag-key-section">
            <h3>Tag Key</h3><br/>
            <div className="tag-key-table-container">
                <table className='bx--data-table bx--data-table--compact bx--data-table--no-border tag-key-table'>
                    <thead>
                        <tr><th>Tag</th><th>Description</th></tr>
                    </thead>
                    <tbody>
                        { availableTags.map( (tag) =>
                            <tr>
                                <td className="tag"><Tag key={tag.type.text} type={tag.type.color}>{tag.type.text}</Tag></td>
                                <td>{tag.description}</td>
                            </tr>
                        ) }
                    </tbody>
                </table>
            </div>
        </section>
    </SimpleContentPage>
)

type ExampleCardProps = {
    title: string,
    description: string,
    href: string,
    tags: { color: TagTypeName, text: string }[]
};

const ExampleCard = ( props: ExampleCardProps ) => {
    const content =
        <div className="example-card">
            <div className="example-card-text">
                <div className="example-card-title">{props.title}</div>
                <div className="example-card-description">{props.description}</div>
            </div>
            <div className="example-card-tags">
                { props.tags.map( t => (<div><Tag key={t.text} type={t.color}>{t.text}</Tag></div>)) }
            </div>
            <div className="example-card-link">
            { <ArrowRight16/> }
            </div>
        </div>

    return <NavLink to={props.href}>{content}</NavLink>
}