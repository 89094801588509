import React, { Fragment } from 'react';
import { SimpleContentPage, slugify } from '../../components';
// import { Link } from 'carbon-components-react';

import './NewsCoverage.css';
import { renderFeaturedNewsItems, renderAdditionalNewsItems } from '../../assets/news';
// import { NavLink } from 'react-router-dom';

const sections = [
    {
        title: "Featured Coverage",
        content: <div className="news-coverage-items">{ renderFeaturedNewsItems() }</div>
    },
    {
        title: "Additional Coverage",
        content: <div className="news-coverage-items">{ renderAdditionalNewsItems() }</div>
    },
]

export const NewsCoverage = () => (
    <SimpleContentPage key="NewsCoverage" title="News Coverage">

        <div className="news-coverage-container">
        {
            sections.map( (section) => (
                <Fragment key={section.title}>
                    <h3 id={slugify(section.title)}>{section.title}</h3><br/>
                    <div className="div-as-paragraph">{section.content}</div><br/><br/>
                </Fragment>
            ))
        }
        </div>
        <div className="blank-space"></div>
    </SimpleContentPage>
)