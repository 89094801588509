import React from 'react';
import { SimpleContentPage, SectionList, Section } from '../../components';
import { Link, TooltipDefinition } from 'carbon-components-react';

import './Introduction.scss'
import { NavLink } from 'react-router-dom';
import { glossary } from '../../assets/glossary';

const IntroImage = ({ text, img, link } : { text: string, img: string, link: string} ) => (
    <div className="intro-img-container">
        <div className="intro-img-image">
            <NavLink to={link}><img alt={text} src={img}></img></NavLink>
        </div>
        <div className="intro-img-text">{text}</div>
    </div>
)

const Term = ( { children }: { children: string }) => {
    if (Object.keys(glossary).includes(children)) {
        return <TooltipDefinition triggerClassName="intro-term-definition" align="center" tooltipText={glossary[children]}>{children}</TooltipDefinition>
    }

    return <>{children}</>;
};

const sections : Section[] = [
    {
        title: "AI FactSheets 360",
        content: <>The goal of the FactSheet project is to foster trust in AI by increasing <Term>transparency</Term> and enabling <Term>governance</Term>. Increased transparency provides information for AI consumers to better understand how the <Term>AI model</Term> or <Term>service</Term><sup className="faq-asterisk">*</sup> was created. This allows a consumer of the model to determine if it is appropriate for their situation. AI Governance enables an enterprise to specify and enforce policies describing how an AI model or service should be constructed and deployed. This can prevent undesirable situations, such as a model training with unapproved datasets, models having biases, or models having unexpected performance variations.
        <br/><br/>
        <em><sup className="faq-asterisk">*</sup> We use the term "service" to mean a service or application.</em>
        <br/><br/>
        Increasing AI transparency and improving AI Governance are two important use cases for the FactSheet project. We now focus on key ideas that are common to both use cases.</>
    },
    {
        title: "What is a FactSheet?",
        content: <>A <Link href="https://arxiv.org/pdf/1808.07261.pdf" target="_blank">FactSheet</Link> is a collection of relevant information (facts) about the creation and deployment of an AI model or service. Facts could range from information about the purpose and criticality of the model, measured characteristics of the dataset, model, or service, or actions taken during the creation and deployment process of the model or service. Such models are created by various roles in a <Term>machine learning lifecycle</Term>, such as a business owner, a <Term>data scientist</Term>, a <Term>model validator</Term>, and <Term>model operator</Term>. Each of the many roles in the lifecycle contribute facts about how the model was created and deployed. For example, the business owner can provide the intended use for the model. The data scientist can describe various data gathering and data manipulation activities. A model tester can describe key testing measurements, and model operator can provide key performance metrics. Together, these facts provide a rich story about the construction of the model, similar to how a school transcript or resume can provide more insight about a student or a job applicant.
        <br/><br/>
        A FactSheet is modeled after a <Link href="https://www.nist.gov/system/files/documents/standardsgov/Sdoc.pdf" target="_blank">supplier’s declaration of conformity</Link> (SDoC), which is used in many different industries to show a product conforms to a standard or technical regulation . However, FactSheets can be rendered in many different formats, not just printed documents.
        <br/><br/>
        <NavLink to="/resources/related-work">Recent work</NavLink> has outlined the need for increased transparency in AI. Although details differ, all are driving towards a common set of attributes that capture essential "facts" about a model. FactSheets take a more general approach to AI transparency than previous work in several ways (read more <Link href="https://arxiv.org/pdf/2006.13796.pdf" target="_blank">here</Link>):
        <br/><br/>
        <ul className="intro-list">
            <li>FactSheets are tailored to the particular AI model or service being documented, and thus can vary in content,</li>
            <li>FactSheets are tailored to the needs of their target audience or consumer, and thus can vary in content and format, even for the same model or service,</li>
            <li>FactSheets capture model or service facts from the entire AI lifecycle,</li>
            <li>FactSheets are compiled with inputs from multiple roles in this lifecycle as they perform their actions to increase the accuracy of these facts.</li>
        </ul>
        <br/>
        FactSheets have the ability to document the final AI service in additional to an individual model for 3 reasons:
        <br/><br/>
        <ol className="intro-list">
            <li>
                AI services constitute the building blocks for many <Term>AI applications</Term>. Developers will query the <Term>service API</Term> and consume its output. An AI service can be an amalgam of many models trained on many <Term>datasets</Term>. Thus, the models and datasets are (direct and indirect) components of an AI service, but they are not the interface to the developer. 
            </li>
            <li>
                There is often an expertise gap between the producer and consumer of an AI service. The production team relies heavily on the training and creation of one or more AI models and hence will mostly contain data scientists. The consumers of the service tend to be developers. When such an expertise gap exists, it becomes more crucial to communicate the attributes of the artifact in a standardized way, as with food nutrition labels or energy ratings for appliances.
            </li>
            <li>
                Systems composed of trusted models may not necessarily be trusted, so it is prudent to also consider transparency and accountability of services in addition to datasets and models. In doing so, we take a functional perspective on the overall service and can test for performance, safety, and security aspects, such as <Term>generalization accuracy</Term>, <Term>explainability</Term>, and <Term>adversarial robustness</Term>.
            </li>
        </ol>
        </>
    },
    {
        title: "Templates: One size does not fit all",
        content: <>We believe that a single standard FactSheet for all use cases is not feasible because the context, industry domain, and target consumer will determine what facts are needed and how they should be rendered. We advocate the use of a <Link href="https://arxiv.org/pdf/1911.08293.pdf" target="_blank">FactSheet template</Link> to capture this information: which facts are of interest and how should they be rendered. For example, one would expect higher-stakes applications will require more comprehensive templates. 
        <br/><br/>
        FactSheets vary based on communication purpose and audience. The <NavLink to="/examples">examples</NavLink> section presents several example FactSheets, with the ability to display the same content in different document formats such as a full report style for a comprehensive public catalog, a short tabular view for quick internal use, and slides for stakeholder presentations during model development.
        <br/><br/>
        <div className="bx--grid bx--grid--full-width bx--grid--no-gutter intro-images">
            <div className="bx--row">
                <div  className="bx--col-md-4 bottom-padded">
                    <IntroImage text="Full Report Format" img="Intro_LongReport.png" link="/examples/max_audio_classifier#full"/>
                </div>
                <div  className="bx--col-md-4 bottom-padded">
                    <IntroImage text="Tabular Format" img="Intro_ShortSummary.png" link="/examples/max_audio_classifier#tabular"/>
                </div>
            </div>
            <div className="bx--row">
                <div  className="bx--col-md-4">
                <IntroImage text="Slide Format" img="Intro_Slides.png" link="/examples/max_audio_classifier#slide"/>
                </div>
            </div>
        </div></>
    },
    {
        title: "The need for transparency",
        content: <>AI models and services are being used in a growing number of high-stakes areas such as <Link href="https://www.datasciencecentral.com/profiles/blogs/credit-risk-prediction-using-artificial-neural-network-algorithm" target="_blank">financial risk assessment</Link>, <Link href="https://spectrum.ieee.org/the-human-os/biomedical/ethics/racial-bias-found-in-algorithms-that-determine-health-care-for-millions-of-patients" target="_blank">medical diagnosis and treatment planning</Link>, <Link href="https://fortune.com/2017/05/19/ai-changing-jobs-hiring-recruiting/" target="_blank">hiring and promotion decisions</Link>, <Link href="https://www2.deloitte.com/us/en/insights/industry/public-sector/artificial-intelligence-technologies-human-services-programs.html" target="_blank">social services eligibility determination</Link>, <Link href="https://arxiv.org/abs/1706.09847" target="_blank">predictive policing</Link>, and <Link href="https://www.propublica.org/article/how-we-analyzed-the-compas-recidivism-algorithm" target="_blank">probation and sentencing recommendations</Link>. For many models there will be risk, compliance, and/or regulatory needs for information covering the nature and intended uses of the model, its overall accuracy, its ability to explain particular decisions, its fairness with respect to protected classes, and at least high-level information about the provenance of training data and assurances that suitable privacy protections have been maintained. In reviewing models within a catalog for suitability in a particular application context, there may be an additional need to easily compare multiple candidates.</>
    },
    {
        title: "Work in Progress",
        content: <>
        We see this work as an <NavLink to="/resources/slack-community">ongoing conversation</NavLink> as there needs to be a multi-stakeholder dialog among all relevant parties: AI developers, AI consumers, regulators, standards bodies, civil society, and professional organizations.
        </>
    },
    {
        title: "Going deeper",
        content: <>
        A key aspect of creating FactSheets is determining the exact information it should contain. The <NavLink to="/methodology">Methodology</NavLink> section highlights our methodology for determining what template (collection of Facts) is appropriate for a particular model/service, use case, and target audience. The <NavLink to="/governance">Governance</NavLink> section provides further details on the collection of facts during the AI lifecycle and gives several examples of how these facts can be leveraged by different roles in the lifecycle to increase overall effectiveness. The <NavLink to="/examples">Examples</NavLink> section provides concrete example FactSheets for several publicly available models, including information on bias, explainability, and adversarial robustness, and insights into some of the challenges in completing these examples. The Resources section provides a collection of information for further learning. It includes links to <NavLink to="/resources/papers">our papers</NavLink>, <NavLink to="/resources/videos">videos</NavLink>, and <NavLink to="/resources/events">events</NavLink>, as well as links to <NavLink to="/resources/related-work">related work</NavLink>, a <NavLink to="/resources/slack-community">slack community</NavLink> where interested parties can collaborate dynamically, a <NavLink to="/resources/glossary">glossary</NavLink>, and a <NavLink to="/resources/faqs">FAQ</NavLink> to help provide additional information on common questions.
        </>
    }
]

export const Introduction = () => (
    <SimpleContentPage key="Introduction" title="Introduction to AI FactSheets">
        <SectionList sections={sections}/>
    </SimpleContentPage>
)