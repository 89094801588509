import React, { useState } from 'react';

import './Example.scss';
import { FactSheet, useFactSheet } from '../../components';
import { TileGroup, RadioTile, Tag, TooltipIcon } from 'carbon-components-react';

import { ReactComponent as LongReportLogo } from '../../assets/example/LongReport.svg'
import { ReactComponent as ShortSummaryLogo } from '../../assets/example/ShortSummary.svg'
import { ReactComponent as SlidesLogo } from '../../assets/example/Slides.svg'
import { ExampleProps } from './utils';
import { useLocation } from 'react-router-dom';
import { getTagDescription } from '../../assets/tags';

type PresentationId = 'web'|'datasheet'|'slides';

type PresentationProps = {
    id: PresentationId,
    text: string;
    description: string;
    logo: React.FC;
}

const presentationOptions: PresentationProps[] = [
    {
        id: 'web',
        text: 'Full Format',
        description: 'The model catalog view',
        logo: LongReportLogo
    },
    {
        id: 'datasheet',
        text: 'Tabular Format',
        description: 'A shorter summary view',
        logo: ShortSummaryLogo
    },
    {
        id: 'slides',
        text: 'Slide Format',
        description: 'A step-by-step presentation view',
        logo: SlidesLogo
    },
]

const getDefaultPresentation = (hash: string) : PresentationId => {
    let ret: PresentationId = presentationOptions[0].id;
    if (hash === "full") return "web"
    if (hash === "tabular") return "datasheet"
    if (hash === "slide") return "slides"
    return ret;
}

export const Example: React.FC<ExampleProps> = ( {model_id, model_name, model_url, model_tags, subtitle} ) => {
    const location = useLocation();
    const factSheet = useFactSheet(model_id);
    const [presentation, setPresentation] = useState(getDefaultPresentation(location.hash.replace("#", "")));

    return <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
        <section className="bx--row example-top">
            <div className="example-title-wrapper">
                <div className="logo-title">
                    {`${model_name} FactSheet`}
                </div>
                <div className="logo-subtitle">
                    {subtitle}
                    {/* Created by data scientists for use in an <Link href={model_url} target='_blank' className="font-size-18">AI model catalog</Link> */}
                </div>
                <div className="tags">
                    { model_tags.map( (t, ix) => (
                        <TooltipIcon tooltipText={getTagDescription(t)} align={model_tags.length -1 === ix ? "end" : (0 === ix ? "start" : "center")}>
                            <Tag key={t.text} type={t.color}>{t.text}</Tag>
                        </TooltipIcon>
                        )
                    )}
                </div>
            </div>
        </section>
        <hr id='content-separator'/>
        <section className="bx--row grey">
            <div className="example-content-wrapper">
                <div className="presentation">
                    <TileGroup
                        className="presentation-chooser-group"
                        defaultSelected={presentation}
                        name="Presentations"
                        onChange={(value) => setPresentation(value as PresentationId)}>
                        { presentationOptions.map( (tile, index) => (
                            <RadioTile className="presentation-rectangular" value={tile.id} id={`tile-${index}`} key={`tile-${index}`}>
                                <div className="presentation-full-tile">
                                    <div className="presentation-tile-image"><tile.logo/></div>

                                    <div className="presentation-tile-text">
                                        <div className="presentation-tile-text-title">{tile.text}</div>
                                        <div className="presentation-tile-text-description">{tile.description}</div>
                                    </div>
                                </div>
                            </RadioTile>
                        ) )}

                    </TileGroup>
                </div>
                <div id="factsheet" className="factsheet">
                    <style>
                    {
                        `@media print {
                            body * {
                                margin: 0;
                                visibility: hidden;
                            }
                            #factsheet, #factsheet * {
                                visibility: visible;
                            }
                            #factsheet {
                                margin: 0;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }

                            #rationale-toggle-container *, #toc *, #toc-collapsed * {
                                display: none;
                            }
                        }`
                    }
                    </style>
                    <FactSheet presentation={presentation} factsheet={factSheet}/>
                </div>
            </div>
          </section>
    </div>
}
