import React from 'react';

import "./FactSheetShortReport.scss";
import { Components } from '../../api/factsheets-api';
import { makeReadable, renderValue } from './RenderingHelpers';

export const FactSheetShortReport = ( props: { factsheet: Components.Schemas.FactSheet } ) => (
    <>
        <div className="bx--grid fs-datasheet-container">
            <div className="bx--row">
                <div  className="bx--col-lg-16">
                    <div className="fs-datasheet-stamp">AI FACTSHEET</div>
                </div>
            </div>
            <div className="bx--row fs-datasheet-content">
                <div  className="bx--col-lg-16">
                    <table className="fs-datasheet-table">
                        <thead>
                        </thead>
                        <tbody>
                            <tr className="top-most-row">
                                <td className="fact">Model Name</td>
                                <td className="value model-title">{makeReadable(props.factsheet.modelId)}</td>
                            </tr>
                            {  props.factsheet.facts.map((fact, index) => (<FactContent fact={fact} key={`fact-content-${index}`}/>) )}
                        </tbody>
                    </table>
                    <br/>
                </div>
            </div>
        </div>
        </>
)

const FactContent = ( props: { fact: Components.Schemas.Fact }) => {
    // transform fact type information into a more suitable data structure
    const fact_to_type: { [indexer: string]: string } = {};
    props.fact.factType?.fields.forEach((ftrf) => {
        if (ftrf.fieldName) {
            fact_to_type[ftrf.fieldName] = ftrf.fieldType ?? "";
        }
    })

    const content = Object.keys(props.fact.value).filter(filterShort).reduce((t, k) => {
        return t + renderValueAsString(k, fact_to_type[k], props.fact.value[k])
    }, "");

    const toRender = content;
    //const toRender = content.split(".\n")[0].split(". ")[0];
    //const toRender = content.split("\n")[0].split(". ")[0];
    //const toRender = content.split(". ")[0];

    return <>
        <tr>
            <td className="fact">{props.fact.displayName}</td>
            <td className="value">{renderValue("markdown", toRender)}</td>
        </tr>
    </>
}

const renderValueAsString = (key: string, type: string, value: any) => {
    // console.log(`Rendering: key: ${key}, type: ${type}, value: ${value}`)
    let content = "";
    if (type === "string" || type === "markdown") {
        content = value
    } else if (type === "table") {
        const table = value as string[][];
        content = table.toString();
    }
    return content;
}

function filterShort(fieldName: string): boolean {
    return fieldName.endsWith("_short");
}