import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import * as serviceWorker from './serviceWorker';

export default function ScrollToTopOrAnchor() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // leverage this global effect to create web analytics info: page view
    const wany = window as any;
    if ( wany && wany.createPageviewTagForSPA) wany.createPageviewTagForSPA();

    // check if an anchor was specified and calculate the offset to scroll to.
    let scrollLocation : ScrollToOptions = { top: 0 };
    if ( hash ) {
      const id = hash.slice(1).replace(/ /g, '_');
      const top = document.getElementById(id)?.getBoundingClientRect()?.top;
      if ( top ) {
        const yOffset = -70;
        const y =  window.pageYOffset + yOffset + (top ?? 0);
        scrollLocation = {top: y };
      }
    }

    // finally, scroll :-)
    window.scrollTo(scrollLocation);
  }, [pathname, hash]);

  return null;
}

ReactDOM.render(
    <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTopOrAnchor />
        <App key="fs-360"/>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
