import React, { Fragment, ReactNode } from 'react';
import { SimpleContentPage } from '../../components';
import { Link } from 'carbon-components-react';

import './Methodology.css';

import { ReactComponent as KnowYourConsumer } from '../../assets/methodology/1.KnowYourConsumer.svg';
import { ReactComponent as KnowYourProducer } from '../../assets/methodology/2.KnowYourProducer.svg';
import { ReactComponent as CreateTemplate } from '../../assets/methodology/3.CreateTemplate.svg';
import { ReactComponent as FirstDraft } from '../../assets/methodology/4.FirstDraft.svg';
import { ReactComponent as ProducersCreateFactSheet } from '../../assets/methodology/5.ProducersCreateFactSheet.svg';
import { ReactComponent as Evaluate } from '../../assets/methodology/6.Evaluate.svg';
import { ReactComponent as Formats } from '../../assets/methodology/7.Formats.svg';

const Steps : React.FC = (props) => (
    <div className="methodology-steps-list">{props.children}</div>
)

const Step : React.FC< {children?: React.ReactNode, title: string, Logo: React.FC}> = ({children, title, Logo}) => (
    <div className="methodology-step">
        <div className="methodology-step-title">
            <Logo/><span className="methodology-step-title-text">{title}</span></div>
        <div>{children}</div>
    </div>
)

const ActionableQualityDimensions = () => <table className='bx--data-table bx--data-table--short bx--data-table--no-border methodology-table'>
<thead>
    <tr>
        <th>Dimension</th>
        <th>Definition</th>
    </tr>
</thead>
<tbody>
    <tr>
        <td>Completeness</td>
        <td>This fact contains all the information necessary to understand its content. No information is missing.</td>
    </tr>
    <tr>
        <td>Conciseness</td>
        <td>This fact contains only information about its content. The fact conveys its content efficiently.</td>
    </tr>
    <tr>
        <td>Relevance</td>
        <td>This fact addresses the category/question it belongs to. All information is on-topic.</td>
    </tr>
    <tr>
        <td>Supporting Evidence</td>
        <td>If this fact requires evidence, it has sufficient evidence to support its claims.</td>
    </tr>
    <tr>
        <td>Vocabulary Choice</td>
        <td>This fact’s terminology and word choice is appropriate to its intended audience.</td>
    </tr>
    <tr>
        <td>Clarity</td>
        <td>This fact’s terminology and other content is sufficiently understandable. Nothing is ambiguous, obscure or incomprehensible.</td>
    </tr>
    <tr>
        <td>Structure</td>
        <td>This fact has a logical structure and information flow.</td>
    </tr>
    <tr>
        <td>Representation</td>
        <td>This fact’s presentation style (text, graph, table, etc.) is appropriate for its content.</td>
    </tr>
    <tr>
        <td>Navigability</td>
        <td>This fact’s representation makes it straightforward to find information of interest.</td>
    </tr>
</tbody>
</table>;

const sections = [
    {
        title: "Background",
        content: <>
        Over the course of our work, we have created nearly two dozen FactSheets. The lessons we have learned along the way are summarized here as a set of steps to follow in creating your own FactSheets. Although the steps are presented as a sequential flow from beginning to end, in reality FactSheet production is highly iterative, especially in the early days of FactSheet adoption within an organization.<br/><br/>
        Further details, including the <strong>issues to consider</strong> and the <strong>questions to explore</strong> with people in your organization, can be found in our <Link href="http://sites.computer.org/debull/A21dec/p47.pdf" target="_blank">paper</Link>. A case study evaluation of an independent group using this methodology can be found <Link href="https://arxiv.org/pdf/2201.13224.pdf" target="_blank">here</Link>. We recommend you read those papers before trying to follow the steps summarized here.
        </>
    },
    {
        title: "Recommended Steps",
        content:
            <Steps>
                <Step title="1. Know your FactSheet consumers" Logo={KnowYourConsumer}>
                FactSheets are produced so they can be consumed. Different people will need different information for different purposes. And different people will have differing knowledge about data preparation, model metrics, and monitoring statistics. The most useful FactSheets will be created with an understanding of who will be consuming them and for what what purposes. Even informal interviews with representative consumers will yield useful insights. So will analysis of existing documentation artifacts and practices.
                </Step>
                <Step title="2. Know your FactSheet producers" Logo={KnowYourProducer}>
                Some facts can be automatically generated by tooling. Some facts can only be produced by a knowledgeable human. Both kinds of facts will be more useful for consumers if fact producers are involved in the earliest phases of incorporating FactSheets in an organization. This step focuses on which facts will be generated during the lifecycle and who will be generating them. Difficulties in producing useful and understandable facts will likely surface during interviews with potential fact producers.
                </Step>
                <Step title="3. Create a FactSheet template" Logo={CreateTemplate}>
                What is learned in these first two steps leads directly to the most important part of creating FactSheets, namely the design of a FactSheet Template. A FactSheet Template will contain what can be thought of as questions. Each individual FactSheet will contain the answers to these questions. While FactSheets will vary across organizations and domains, common templates can be developed within an organization to standardize the information produced and consumed about AI models and services. This will aid in model comparison and allow trends and patterns to be observed over time. In creating each template it is important to determine where the information will be coming from (e.g., automatically from a tool versus manually from a person) and the amount of detail to be collected. Decisions will need to be made about what information is required and what is optional. Ideally, each template will provide examples of suitable content to help everyone understand what is expected.
                </Step>
                <Step title="4. Fill In FactSheet Template" Logo={FirstDraft}>
                This step is where you will attempt to fill in your FactSheet Template for the first time. As you do this, informally assess the quality of the template itself. While this assessment is not a substitute for further work with fact producers and consumers (to follow), it may quickly highlight where improvements are needed. In doing this assessment try to reflect on the template, and the FactSheets it will generate, from producer's and consumer's points of view.
                </Step>
                <Step title="5. Have actual producers create a FactSheet" Logo={ProducersCreateFactSheet}>
                At this point you have a solid template and a good sense of how it might be used to create FactSheets. The next step is to have actual fact producers fill in the template for their phase of the lifecycle. If there is a question about model purpose, find someone who would actually be entering that information and have them answer the corresponding questions. Ask a data scientist to answer the questions related to the development and testing of an actual model. If this model was validated, ask the model validator to enter information about that process. Similarly, have a person responsible for model deployment answer those questions.<br/>
                If the lifecycle is not that structured, have the person responsible for most of the work create this FactSheet.
                </Step>
                <Step title="6. Evaluate actual FactSheet with consumers" Logo={Evaluate}>
                There are two things to evaluate in this step, the content of the FactSheet and the manner of its presentation. To the extent possible, evaluate these separately after first making sure that your consumer or consumers are aligned on the purpose the FactSheet is meant to serve. The goal of the first part of the evaluation is to see how well the content of the FactSheet meets the specifically-designed-for information needs of the consumer. The goal of the second part of the evaluation is to see if the way the information is presented, for example in text, tables, and graphs, meets those needs. Be prepared to incorporate what you learn back into the template designed in Step 3.<br/><br/>
                To help evaluate the quality of a FactSheet, we developed a set of actionable quality dimensions. These dimensions are based on prior research in Software Documentation and Communication and are intended to help FactSheet evaluators reason about why a given Fact is not being communicated effectively. The dimensions are presented in the table below. More information about these dimensions can be found in our <Link href="https://arxiv.org/pdf/2011.08774.pdf" target="_blank">paper</Link>.<br/><br/>
                <ActionableQualityDimensions/>
                </Step>
                <Step title="7. Devise other templates and forms for other audiences and purposes" Logo={Formats}>
                By now you will have created a refined FactSheet Template for use by others. They will be able to create useful and consumable FactSheets with that template. But there is more to do. There may well be other consumers that need to be supported. Perhaps it is time to turn from an inward focus to an outer one, crafting templates for FactSheets to be consumed by external review boards or regulators. Or it may be time to support other stakeholders not directly involved in the AI lifecycle such as sales personnel or end consumers. Other formats for the same content may need to be created as well. The above steps can be followed once again. You will have learned a surprising amount about how to create FactSheet Templates and FactSheets from having gone through this process once. It will go faster and more smoothly now.
                </Step>
            </Steps>
    }
]

export const Methodology = () => (
    <SimpleContentPage key="Methodology" title="A Methodology for Creating AI FactSheets">
        {
            sections.map( (section) => (
                <Fragment key={section.title}>
                    <h3>{section.title}</h3><br/>
                    <div className="div-as-paragraph">{section.content}</div><br/><br/>
                </Fragment>
            ))
        }
    </SimpleContentPage>
)