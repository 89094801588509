import React from "react";

export const glossary : { [key: string]: React.ReactNode } = {
    "transparency":
        "an increased understanding of how AI was created and deployed",
    "governance":
        "the ability to control how AI is created and deployed",
    "AI model":
        "a program component that is generated by learning patterns in training data to make predictions on new data, such as a loan application.",
    "service":
        "an executable program, deployed behind an API, that allows it to respond to program requests from other programs or services",
    "machine learning lifecycle":
        "the collection of steps used to develop and deploy a machine learning model",
    "data scientist":
        "a person who collects and transforms datasets and machine learning algorithms to construct a machine learning model",
    "model validator":
        "a person who tests, or validates, an AI model developed by a data scientist",
    "model operator":
        "a person who deploys and monitors an AI model so that it can be called by others",
    "AI applications":
        "a program that contains one or more AI models",
    "service API":
        "the interface of an AI service that software communicates with to use the functionality of the service",
    "datasets":
        "a collection of information that a machine learning algorithm uses to \"learn\"  patterns to use for its predictions",
    "generalization accuracy":
        "the level of accuracy of a machine learning model on new data, i.e, that that was not part of the training dataset",
    "explainability":
        "the ability to explain the reasons for a machine learning model prediction",
    "adversarial robustness":
        "the ability of a machine learning model to function correctly when confronted with an external agent trying to fool it",
    "FactSheet":
        <>a collection of key Facts about how an AI model or service<sup className="faq-asterisk">*</sup> was developed, tested, deployed, monitored, and modified over time that is rendered in a way that is understandable to the consumer
            <br/>
            <em><sup className="faq-asterisk">*</sup> We use the term "service" to mean a service or application.</em>
        </>,
    "Fact": "a unit of information about an AI model or service, such as model purpose, datasets used for training and testing, model architecture, accuracy and fairness metrics, expected and realized performance including drift over time",
    "FactSheet Template": "a convenient way to specify the subset of Facts that are useful for a particular purpose and how they should be rendered",
}