import { LinkableText } from "../components"

export type Event = {
  date: string, // expected format: "YYYY/m/d"
  links: LinkableText[],
  title: string,
  type?: string,
  venue: LinkableText[],
}

export const events: Event[] = [
  {
    date: "2021/4/7",
    links: [
    ],
    title: "Trusted AI",
    type: "guest lecture",
    venue: [
      { text: "Trustworthy Machine Learning", url: "http://www.ece.ubc.ca/~mjulia/teaching/EECE571J-Trust-W20T2/index.html" },
    ]
  },
  {
    date: "2021/3/3",
    links: [
      { text: "Video", url: "https://www.youtube.com/watch?v=o8W5Z867hBQ" }
    ],
    title: "AI Governance: Driving Compliance, Efficiency, and Outcomes",
    type: "keynote",
    venue: [
      { text: "Chief Data & Analytics Officers Financial Services", url: "https://cdaofs.coriniumintelligence.com/" },
    ]
  },
  {
    date: "2021/2/3",
    links: [
      { text: "Video", url: "https://www.youtube.com/watch?v=gsHAYvokSdA" }
    ],
    title: "Race, Tech, and Civil Society: Tools for Combating Bias in Datasets and Models",
    type: "panel",
    venue: [
      { text: "Stanford Center for Comparative Studies in Race and Ethnicity", url: "https://ccsre.stanford.edu/" },
    ]
  },
  {
    date: "2021/1/26",
    links: [
    ],
    title: "What are the roles of explanations throughout the AI life cycle?",
    type: "panel",
    venue: [
      { text: "NIST Explainable AI Workshop", url: "https://www.nist.gov/news-events/events/2021/01/explainable-ai-workshop" },
    ]
  },
  {
    date: "2020/12/11",
    links: [
    ],
    title: "Increasing Trust and Transparency in AI",
    type: "talk",
    venue: [
      { text: "Singapore FinTech Festival", url: "https://www.fintechfestival.sg/" },
    ]
  },
  {
    date: "2020/12/3",
    links: [
    ],
    title: "Trusted AI",
    type: "talk",
    venue: [
      { text: "AI Journey Conference", url: "https://ai-journey.ru/en/conference" },
    ]
  },
  {
    date: "2020/10/21",
    links: [
    ],
    title: "Governance is key to embed AI at scale",
    type: "panel",
    venue: [
      { text: "Digital Transformation World Series 2020", url: "https://dtw.tmforum.org/agenda/episode-1-laying-the-ai-foundation/" },
    ]
  },
  {
    date: "2020/9/25",
    links: [
      { text: "Video", url: "https://mediasite.mms.rpi.edu/Mediasite5/Play/8d8f9972ebcb42c795eb68eb85757ea71d?catalog=afd40849a26e4cf695417855ee8d60d421" }
    ],
    title: "What Are the Research Challenges in Trusted AI?",
    type: "talk",
    venue: [
      { text: "AI and Cybersecurity Issues in Financial Services", url: "https://lallyschool.rpi.edu/node/169931#overlay-context=node/169931" },
    ]
  },
  {
    date: "2020/6/17",
    links: [
      { text: "Video", url: "https://www.youtube.com/watch?v=V_ivECnlQGM" }
    ],
    title: "Increasing Trust in AI",
    type: "guest lecture",
    venue: [
      { text: "Ethical Implications of AI", url: "http://www.bigdata.uni-frankfurt.de/ethical-implications-ai-ss2020/" },
      { text: "Goethe Universtat Frankfurt" }
    ]
  },
]