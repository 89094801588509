import React from 'react';

import { FS_ICON_INTRODUCTION, FS_ICON_FEATURED_EXAMPLE, FS_ICON_AUTHORING, FS_ICON_SLACK, FS_ICON_PAPERS, FS_ICON_PROCESS } from '../utils';
import { FactSheetsCard, FactSheetsFooter } from '../../components';

import './Home.scss';
import { examplesInfo } from '../../assets/examples';
import { Button } from 'carbon-components-react';
import { PlayOutline16 } from '@carbon/icons-react';

const featuredExamples = [
  'max_audio_classifier',
  'max_object_detector',
  // 'max_weather_forecaster',
  // 'max_text_sentiment_classifier',
  // 'max_image_caption_generator',
  // 'max_breast_cancer_detection',
  'hmda',
]

export const Home = () => {
  const content = (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
      <section className="bx--row stripe">
        <div className="bx--col-sm-4">
          <div className="cell-wrapper">
            <img src="fslogo.png" alt="FactSheets Logo" id='fs360-img'></img>
            <div className="home-logo-title">AI FactSheets 360</div>
            <div className="home-logo-text">
              This site provides an overview of the FactSheet project, a research effort to foster trust in AI by increasing transparency and enabling governance.
            </div>
            <div className="home-logo-video-button float-left">
              <Button kind="tertiary" size="default" renderIcon={PlayOutline16} href="./resources/videos">Website Overview</Button>
            </div>
            <div className="home-logo-video-button float-left">
              <Button kind="tertiary" size="default" renderIcon={PlayOutline16} href="./governance">AI Governance Overview</Button>
            </div>
          </div>
        </div>
      </section>
      <hr id='content-separator'/>
      <section className="bx--row grey stripe">
        <div className="bx--col-lg-16">
          <div className="cell-wrapper">
            <p className="section-title">Learn More</p>
            <div className="home-cards">
              <FactSheetsCard icon={FS_ICON_INTRODUCTION} href="/introduction">Introduction to FactSheets</FactSheetsCard>
              <FactSheetsCard icon={FS_ICON_AUTHORING} href="/methodology">A Methodology for Creating AI FactSheets</FactSheetsCard>
              <FactSheetsCard icon={FS_ICON_PROCESS} href="/governance">AI Lifecycle Governance</FactSheetsCard>
            </div>
          </div>
        </div>
      </section>
      <section className="bx--row grey stripe">
          <div className="bx--col-lg-16">
          <div className="cell-wrapper">
            <p className="section-title">Featured Examples</p>
            <div className="home-cards">
              {
                featuredExamples.map( fe => {
                  const ix = examplesInfo.findIndex( (mt) => mt.id === fe);
                  if (ix >= 0) {
                    const ex = examplesInfo[ix];
                    return <FactSheetsCard tags={ex.tags} icon={FS_ICON_FEATURED_EXAMPLE} key={ex.id} href={`/examples/${ex.id}`}>
                      {ex.text}
                    </FactSheetsCard>
                  }
                  return <></>
                })
              }
            </div>
          </div>
        </div>
      </section>
      <section className="bx--row grey stripe last-section">
        <div className="bx--col-lg-16">
          <div className="cell-wrapper">
            <p className="section-title">Featured Resources</p>
            <div className="home-cards">
              <FactSheetsCard icon={FS_ICON_PAPERS} href="/resources/papers">Our Papers</FactSheetsCard>
              <FactSheetsCard icon={FS_ICON_SLACK} href="/resources/slack-community">Slack Community</FactSheetsCard>
            </div>
          </div>
        </div>
      </section>
      <FactSheetsFooter/>
    </div>
  );

  return <>{content}</>;
};
