// CONSTANTS

// Stages
export const STAGE_BUSINESS_REQUEST = "Business Request";
export const STAGE_DATA_TRANSFORM = "Data Transform";
export const STAGE_MODEL_GENERATION = "Model Generation";
export const STAGE_VALIDATION_DS_MODEL = "Validation (Data Scientist's Model)";
export const STAGE_VALIDATION_CH_MODEL = "Validation (Challenge Model)";
export const STAGE_DEPLOY_MODELS = "Deploy Models";
export const STAGE_APP_MONITORING = "Application Monitoring";

export const orderedStages = [
  STAGE_BUSINESS_REQUEST,
  STAGE_DATA_TRANSFORM,
  STAGE_MODEL_GENERATION,
  STAGE_VALIDATION_DS_MODEL,
  STAGE_VALIDATION_CH_MODEL,
  STAGE_DEPLOY_MODELS,
  STAGE_APP_MONITORING
]

// FactTypeIDs
// The different filters and specific rendering functions reference these constants so if a new
// FactSheet with brand new FacTypeIds comes along, it'd be only necessary to change the mapping from
// the constant to the new ID in here.
// If a new FactTypeId is introduced, a new constant should be created here and used throughout the code
// instead of just pasting the GUID.
export const FACT_PURPOSE = "25e8575e37cfadf36664f04b5e000786";
export const FACT_RISK_LEVEL = "25e8575e37cfadf36664f04b5e002417";
export const FACT_MODEL_POLICY = "04be07343395d2656365c2a104fb4244";
//---
export const FACT_DATASET = "b50942c7222f70a68c1b497ea82b04fc";
export const FACT_SEL_RELEVANT_RECORDS = "cd46000211a6e3b1e14c4c1d3fc742f4";
export const FACT_SEL_ACTION_FOR_LOAN = "31fc4f1073ec0880d1b1e1e2fc426d2d";
export const FACT_NEW_DERIVED_RACE_ETHNICITY = "8b684dcbb8800dfa01a3ce95acf1ba9b";
export const FACT_NEW_LOAN_APPROVED = "cd46000211a6e3b1e14c4c1d3fc74e9d";
export const FACT_SEL_LOAN_TERM = "75a2697e7ccec87c7b332ed6a8f0225a";
export const FACT_NEW_GENDER = "8b684dcbb8800dfa01a3ce95acf1e25a";
export const FACT_SEL_GENDER_MALE_FEMALE = "364e98747a91acd03700f740acaa59bf";
export const FACT_DEL_NA_VALUES = "a19f2a983d9d2a55afbac03fea7421ba";
export const FACT_DEL_AUTO_UNDERWRITING_SYSTEM = "34b2df4df43fbb0d93ee032d97f5cb46";
export const FACT_DEL_DEBT_TO_INCOME_RATIO = "f0e949f441304f8865cd9b54a1084ffb";
export const FACT_FMT_MODIFIED_DEBT_TO_INCOME_RATIO = "aacbedefe8619b4fde69cd69cdb4748a";
export const FACT_DEL_APPLICANT_AGE = "31fc4f1073ec0880d1b1e1e2fc429990";
export const FACT_DEL_INIT_PAY_TO_INSTITUTION = "31fc4f1073ec0880d1b1e1e2fc4299d0";
export const FACT_NEW_MOD_PREAPPROVAL = "34b2df4df43fbb0d93ee032d97f5fbf9";
export const FACT_NEW_MOD_LOAN_TERM = "aacbedefe8619b4fde69cd69cdb48c51";
export const FACT_NEW_MOD_APP_CREDIT_LOAN_TYPE = "cd46000211a6e3b1e14c4c1d3fc7ccc4";
export const FACT_NEW_MOD_AUS_1 = "31fc4f1073ec0880d1b1e1e2fc42b2d9";
export const FACT_NEW_MOD_CONFORMING_LOAN_LIMIT = "fb9cd381b38388b202e3aadc1af89a25";
export const FACT_NEW_MOD_APPLICANT_AGE = "31fc4f1073ec0880d1b1e1e2fc42d092";
export const FACT_NEW_DATASET_DISTRIBUTIONS = "7f89f04d3e3d0424979c260a1c05e535";
//--
export const FACT_TRAINING_DATA_PATH = "56532fc9aa25ae57023e141ba0ddde88";
export const FACT_TRAINING_TARGET_PATH = "3bfd42dea28f53c1a2aa8f653db71017";
export const FACT_TRAINING_ALGORITHM = "ba0f3102ab239db97cbfd769296ae0fb";
export const FACT_TRAINING_ACCURACY = "4fdf994413851dac2c267233fb31db32";
export const FACT_TESTING_ACCURACY = "56532fc9aa25ae57023e141ba0ddcbd2";
export const FACT_FEATURE_COLUMNS = "e4189129bab0640cc59e78f6f1a91247";
export const FACT_CATEGORICAL_COLUMNS = "a5693a8742f92a037527e0c722de315a";
export const FACT_FAIRNESS_COLUMNS= "97068c32db3b8d92e00dd85ccb09a3d8";
export const FACT_PROTECTED_ATTR_NAMES= "3396322d446b8f46aacd18bbf06738c8";
export const FACT_PRIVILIEGED_GROUPS = "e38787f7ece5b986bc23e2b441c5645a";
export const FACT_UNPRIVILEGED_GROUPS = "3396322d446b8f46aacd18bbf05aeb6c"
export const FACT_FAIRNESS_FEATURE_LIST = "c39d4be25f1af858124f60bea1b67ae3"
export const FACT_FAIRNESS_MON_ENABLE_FAV_CLASSES = "4a76bac7690209b68592294ced3c1288";
export const FACT_FAIRNESS_MON_ENABLE_UNFAV_CLASSES = "aebe4f6622c9371e9cb90b6cb5994648";
export const FACT_FAIRNESS_MON_ENABLE_MIN_RECORDS = "cd89f148719d774d8d4746c76b17b3fd";
export const FACT_FAIRNESS_METRICS_AIF360 = "67d265765e3fa6dec5e64bcdca6a36b5";
export const FACT_EXPLAINABILITY_METRICS_AIX360 = "63e09ea0a8f4794d7c050c201ed73dbb";
export const FACT_ADVERSARIAL_ROBUSTNESS_METRICS_ART = "6180cd9f6ede7c303ce50777ff2cc15f";
export const FACT_QUALITY_METRICS = "532b01a240c1111bd94d3c82b736a7b4";
// ---------------
export const FACT_SUBS_BINDING_UID = "9d58d51f5395007a0a4e1c3d1a1a8bcb";
export const FACT_SUBS_PROBLEM_TYPE = "c5d8535b6c5db4f7ebe8ac480a23c6fc";
export const FACT_SUBS_INPUT_DATA_TYPE = "5dba2a21819a0bdc2a119d93c8f23588";
export const FACT_SUBS_LABEL_COLUMN = "3eff8423492566737796e8225ec8e1b9";
export const FACT_SUBS_PREDICTION_COLUMN = "c5d8535b6c5db4f7ebe8ac480a23eaf4";
export const FACT_SUBS_PROB_COLUMN = "af55bea067fce4df45ec6023fdb04194";
export const FACT_DEPLOYMENT = "04fa1318808b74a5ab56acb64f6a5153";
// ---------------
export const FACT_QUALITY_MON_ENABLE_THRESHOLD = "aebe4f6622c9371e9cb90b6cb5925b87";
export const FACT_QUALITY_MON_ENABLE_MIN_RECORDS = "c5d8535b6c5db4f7ebe8ac480ac2cdcb";
export const FACT_QUALITY_MON_ENABLE_MAX_RECORS = "3c910d785bb8de70952f8e9bec099239";
export const FACT_FAIRNESS_OPENSCALE_METRICS = "532b01a240c1111bd94d3c82b736a8cd";
export const FACT_FAIRNESS_OPENSCALE_THRESHOLD = "aebe4f6622c9371e9cb90b6cb5928888";
export const FACT_FAIRNESS_OPENSCALE_NUMRECORDS = "c5d8535b6c5db4f7ebe8ac480ac29999";
// ---------------

// Personas' fact filters

// The fact filter consists of a FactTypeId (i.e. ftid) and a list of which stages is the persona
// interested in viewing.

export type PersonaFactFilter = {
  ftid: string,
  stages: string[]
}[];

export const PERSONA_FILTER_BUSINESS_OWNER : PersonaFactFilter = [
  { ftid: FACT_PURPOSE, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_RISK_LEVEL, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_MODEL_POLICY, stages: [ STAGE_BUSINESS_REQUEST ] },
]

export const PERSONA_FILTER_DATA_SCIENTIST : PersonaFactFilter = [
  { ftid: FACT_PURPOSE, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_MODEL_POLICY, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_DATASET, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_SEL_RELEVANT_RECORDS, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_SEL_ACTION_FOR_LOAN, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_DERIVED_RACE_ETHNICITY, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_LOAN_APPROVED, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_SEL_LOAN_TERM, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_GENDER, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_SEL_GENDER_MALE_FEMALE, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_NA_VALUES, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_AUTO_UNDERWRITING_SYSTEM, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_DEBT_TO_INCOME_RATIO, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_FMT_MODIFIED_DEBT_TO_INCOME_RATIO, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_APPLICANT_AGE, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_INIT_PAY_TO_INSTITUTION, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_PREAPPROVAL, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_LOAN_TERM, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_APP_CREDIT_LOAN_TYPE, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_AUS_1, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_CONFORMING_LOAN_LIMIT, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_APPLICANT_AGE, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_DATASET_DISTRIBUTIONS, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_TRAINING_ACCURACY, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_TESTING_ACCURACY, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_TRAINING_DATA_PATH, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_TRAINING_TARGET_PATH, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_TRAINING_ALGORITHM, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FEATURE_COLUMNS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_CATEGORICAL_COLUMNS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_COLUMNS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_PROTECTED_ATTR_NAMES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_PRIVILIEGED_GROUPS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_UNPRIVILEGED_GROUPS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_FEATURE_LIST, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_FAV_CLASSES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_UNFAV_CLASSES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_MIN_RECORDS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_METRICS_AIF360, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_EXPLAINABILITY_METRICS_AIX360, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_ADVERSARIAL_ROBUSTNESS_METRICS_ART, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_QUALITY_METRICS, stages: [ STAGE_MODEL_GENERATION ] },
]

export const PERSONA_FILTER_MODEL_VALIDATOR : PersonaFactFilter = [
  { ftid: FACT_PURPOSE, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_RISK_LEVEL, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_MODEL_POLICY, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_SEL_RELEVANT_RECORDS, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_SEL_ACTION_FOR_LOAN, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_DERIVED_RACE_ETHNICITY, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_LOAN_APPROVED, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_SEL_LOAN_TERM, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_GENDER, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_SEL_GENDER_MALE_FEMALE, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_NA_VALUES, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_AUTO_UNDERWRITING_SYSTEM, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_DEBT_TO_INCOME_RATIO, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_FMT_MODIFIED_DEBT_TO_INCOME_RATIO, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_APPLICANT_AGE, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_DEL_INIT_PAY_TO_INSTITUTION, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_PREAPPROVAL, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_LOAN_TERM, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_APP_CREDIT_LOAN_TYPE, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_AUS_1, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_CONFORMING_LOAN_LIMIT, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_NEW_MOD_APPLICANT_AGE, stages: [ STAGE_DATA_TRANSFORM ] },
  { ftid: FACT_TESTING_ACCURACY, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_CH_MODEL ] },
  { ftid: FACT_TRAINING_DATA_PATH, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_TRAINING_TARGET_PATH, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_TRAINING_ALGORITHM, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_CH_MODEL ] },
  { ftid: FACT_TRAINING_ACCURACY, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_CH_MODEL ] },
  { ftid: FACT_FEATURE_COLUMNS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_CATEGORICAL_COLUMNS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_COLUMNS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_PROTECTED_ATTR_NAMES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_PRIVILIEGED_GROUPS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_UNPRIVILEGED_GROUPS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_FEATURE_LIST, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_FAV_CLASSES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_UNFAV_CLASSES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_MIN_RECORDS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_METRICS_AIF360, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_DS_MODEL, STAGE_VALIDATION_CH_MODEL ] },
  { ftid: FACT_EXPLAINABILITY_METRICS_AIX360, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_DS_MODEL, STAGE_VALIDATION_CH_MODEL ] },
  { ftid: FACT_ADVERSARIAL_ROBUSTNESS_METRICS_ART, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_DS_MODEL, STAGE_VALIDATION_CH_MODEL ] },
  { ftid: FACT_QUALITY_METRICS, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_DS_MODEL, STAGE_VALIDATION_CH_MODEL ] },
]

export const PERSONA_FILTER_AI_OPS_ENGINEER : PersonaFactFilter = [
  { ftid: FACT_PURPOSE, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_PROTECTED_ATTR_NAMES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_PRIVILIEGED_GROUPS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_UNPRIVILEGED_GROUPS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_FEATURE_LIST, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_FAV_CLASSES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_UNFAV_CLASSES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_MIN_RECORDS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_QUALITY_MON_ENABLE_THRESHOLD, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_QUALITY_MON_ENABLE_MIN_RECORDS, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_QUALITY_MON_ENABLE_MAX_RECORS, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_FAIRNESS_METRICS_AIF360, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_EXPLAINABILITY_METRICS_AIX360, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_ADVERSARIAL_ROBUSTNESS_METRICS_ART, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_QUALITY_METRICS, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_FAIRNESS_OPENSCALE_METRICS, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_FAIRNESS_OPENSCALE_THRESHOLD, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_FAIRNESS_OPENSCALE_NUMRECORDS, stages: [ STAGE_APP_MONITORING ] },
]

export const PERSONA_FILTER_CHIEF_RISK_OFFICER : PersonaFactFilter = [
  { ftid: FACT_PURPOSE, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_MODEL_POLICY, stages: [ STAGE_BUSINESS_REQUEST ] },
  { ftid: FACT_PROTECTED_ATTR_NAMES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_PRIVILIEGED_GROUPS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_UNPRIVILEGED_GROUPS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_FEATURE_LIST, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_FAV_CLASSES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_UNFAV_CLASSES, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_FAIRNESS_MON_ENABLE_MIN_RECORDS, stages: [ STAGE_MODEL_GENERATION ] },
  { ftid: FACT_QUALITY_MON_ENABLE_THRESHOLD, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_QUALITY_MON_ENABLE_MIN_RECORDS, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_QUALITY_MON_ENABLE_MAX_RECORS, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_FAIRNESS_METRICS_AIF360, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_DS_MODEL, STAGE_APP_MONITORING ] },
  { ftid: FACT_EXPLAINABILITY_METRICS_AIX360, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_DS_MODEL, STAGE_APP_MONITORING ] },
  { ftid: FACT_ADVERSARIAL_ROBUSTNESS_METRICS_ART, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_DS_MODEL, STAGE_APP_MONITORING ] },
  { ftid: FACT_QUALITY_METRICS, stages: [ STAGE_MODEL_GENERATION, STAGE_VALIDATION_DS_MODEL, STAGE_APP_MONITORING ] },
  { ftid: FACT_FAIRNESS_OPENSCALE_METRICS, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_FAIRNESS_OPENSCALE_THRESHOLD, stages: [ STAGE_APP_MONITORING ] },
  { ftid: FACT_FAIRNESS_OPENSCALE_NUMRECORDS, stages: [ STAGE_APP_MONITORING ] },
]