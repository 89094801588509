import React from 'react';
import { SimpleContentPage } from '../../components';

import './Glossary.css';
import { glossary } from '../../assets/glossary';

export const Glossary = () => (
    <SimpleContentPage key="Glossary" title="Glossary of Terms">
        <div className="div-as-paragraph">
        {
            Object.keys(glossary).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map( (term) => (
                <div key={term}>
                    <span className="term">{term}</span><br/>
                    <span className="definition">{glossary[term]}</span><br/><br/>
                </div>
            ))
        }
        </div>
    </SimpleContentPage>
)