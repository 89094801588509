import React, { Fragment } from 'react';
import { SimpleContentPage, slugify } from '../../components';
import { Link } from 'carbon-components-react';
import moment from 'moment';

import "./Videos.css";
import { videos } from '../../assets/videos';

export const Videos = () => (
    <SimpleContentPage key="Videos" title="Videos">
        <div className="videos-container">
            <h3>
                Overview of this website
            </h3><br/>
            <div className="video-constraint">
                <div className="videoWrapper">
                    <iframe title="AIFS 360 Video Overview" frameBorder="0" allowFullScreen width="560" height="315" src="https://www.youtube.com/embed/qIqNYChnFs8" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
            </div>
            <br/>
            <br/>
            { Object.keys(videos).map( cat => (
                <Fragment key={cat}>
                    <h3 id={slugify(cat)}>{cat}</h3><br/>
                    <div className="div-as-paragraph">
                        <ul>
                        {
                            videos[cat].map( video => (
                                <li>
                                    <Link href={video.title.url} target="_blank"><strong>{video.title.text}</strong></Link> ({renderDate(video.date)}, {renderDuration(video.duration)}) by {renderAuthorsList(video.authors)}.
                                </li>
                            ))
                        }
                        </ul>
                    </div>
                    <br/><br/>
                </Fragment>
            ))}
        </div>
    </SimpleContentPage>
)

function renderAuthorsList( authors: string[] ) {
    return authors.join((authors.length !== 2)  ? ", " : " and " );
}

function renderDate( date: string ) {
    return moment(date, "YYYY/MM/DD").format('MMMM D, YYYY')
}

function renderDuration( duration: number ) {
    return `${duration} ${duration > 1 ? "mins" : "min"}`
}