import React, { Fragment, useState, ReactNode } from 'react';
import { Button, ToggleSmall, Select, SelectItem } from 'carbon-components-react';

import "./FactSheetLongCatalog.scss";
import { Components } from '../../api/factsheets-api';
import { renderValue, makeReadable } from './RenderingHelpers';
// import { UpToTop16 } from '@carbon/icons-react';

type Rationale = {
    [key: string]: ReactNode;
}

const toggled = false;

export const FactSheetLongCatalog = ( props: { factsheet: Components.Schemas.FactSheet, rationale: Rationale } ) => {
    const [rationale, setRationale] = useState(toggled);
    return <>
        <div className="bx--grid fs-web-container">
            <div className="bx--row">
                <div  className="bx--col-lg-16">
                    <div className="fs-web-stamp">AI FACTSHEET</div>
                </div>
            </div>
            <div className="bx--row">
                <div  className="bx--col-lg-16">
                    <div className="rationale-toggle-container" id="rationale-toggle-container">
                        <ToggleSmall
                            defaultToggled={toggled}
                            disabled={Object.keys(props.rationale).length === 0}
                            aria-label="Author notes toggle"
                            className="rationale-toggle"
                            id="rationale-toggle"
                            labelA="Hide"
                            labelB="Show"
                            labelText="Author Notes"
                            onToggle={(checked) => setRationale(checked)}
                        />
                    </div>
                </div>
            </div>
            <div className="bx--row fs-web-title">
                <div  className="bx--col-lg-12 bx--offset-lg-4">
                    <h1 id={props.factsheet.modelId}>{makeReadable(props.factsheet.modelId)}</h1>
                </div>
            </div>
            <div className="bx--row fs-web-content">
                <div  className="bx--col-lg-4">
                    <TableOfContents facts={props.factsheet.facts}/>
                </div>
                <div  className="bx--col-lg-10">
                    {  props.factsheet.facts.map((fact, index) => (
                        <FactContent showRationale={rationale} fact={fact} rationale={getRationaleForFact(fact, props.rationale)}
                            key={`fact-content-${index}`}/>
                    ) )}
                </div>
            </div>
        </div>
        </>
}

function getRationaleForFact(fact: Components.Schemas.Fact, rationales: Rationale) : ReactNode
{
    if (fact.factTypeId in rationales) {
        return rationales[fact.factTypeId];
    }
    return undefined;
}

const TableOfContents = ( props: { facts: Components.Schemas.Fact[] }) => (
    <>
        <div id="toc-expanded">
        {
            props.facts.map((fact, index) => (
                <p key={`tc-item-${index}`}>
                    <Button kind='ghost' size='small' onClick={() => scrollToFact(fact.displayName) }>
                        {fact.displayName}
                    </Button>
                </p>)
            )
        }
        </div>
        <div id="toc-collapsed">
            <Select defaultValue="placeholder-item" id="toc-collapsed-select"
                    labelText="Navigate to:" onChange={(event) => scrollToFact(event.target.value) }>
                <SelectItem disabled hidden value="placeholder-item" text="Choose a section"/>
            {
                props.facts.map((fact, index) => (
                    <SelectItem key={`tc-col-item-${index}`} value={fact.displayName} text={fact.displayName}/>
                    )
                )
            }
            </Select>
        </div>
    </>
)

function scrollToFact(factName: string) {
    const id = factName.replace(/ /g, '_');
    const yOffset = -50;
    const top = document.getElementById(id)?.getBoundingClientRect()?.top;
    const y =  window.pageYOffset + yOffset + (top ?? 0);

    window.scrollTo({top: y, behavior: 'smooth'});
}

const FactContent = ( props: { fact: Components.Schemas.Fact, showRationale: boolean, rationale: ReactNode }) => {
    // transform fact type information into a more suitable data structure
    let fact_to_type: { [indexer: string]: string } = {};
    props.fact.factType?.fields.forEach((ftrf) => {
        if (ftrf.fieldName) {
            fact_to_type[ftrf.fieldName] = ftrf.fieldType ?? "";
        }
    })

    return <div key={props.fact.displayName.replace(/ /g, '_')} id={props.fact.displayName.replace(/ /g, '_')}>
        <h5>
            {props.fact.displayName}
            {/* <span className="nav-to-top"> <a className="go-to-top" onClick={() => scrollToFact(props.fact.modelId)}><UpToTop16/></a></span> */}
        </h5>
        <br/>
        <div>
        { Object.keys(props.fact.value).filter(filterLong).map((k) => <Fragment key={k}>{ renderValue(fact_to_type[k], props.fact.value[k]) }</Fragment>) }
        </div>
        { props.rationale && props.showRationale && <FactRationale>{props.rationale}</FactRationale> }
    </div>
}

const FactRationale = (props: { children: ReactNode} ) => (
    <div className='rationale-content div-as-paragraph'>
        <strong>Author notes: </strong>{props.children}
    </div>
)

function filterLong(fieldName: string): boolean {
    return !(fieldName.endsWith("_short") || fieldName.endsWith("_slide"))
}
