import React from 'react';
import { ToolBox32 } from '@carbon/icons-react';

export const UnderConstruction = () => (
    <div className="bx--grid bx--grid--full-width bx--grid--no-gutter">
        <section id="under-construction" className="bx--row">
            <div  className="bx--col-lg-16">
                <h1 style={{textAlign: "center", fontSize: 50}}>
                    <ToolBox32/><br/>Under Construction
                </h1>
            </div>
        </section>
    </div>
)
