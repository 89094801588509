import React from 'react';
import { CarbonIconType, Launch16, ArrowRight16 } from '@carbon/icons-react';

import "./FactSheetsCard.scss";
import { NavLink } from 'react-router-dom';
import { Link, TagTypeName, Tag } from 'carbon-components-react';

type FactSheetsCardProps = {
    children: React.ReactNode,
    icon?: CarbonIconType,
    href: string,
    external?: boolean,
    extraClass?: string,
    tags?: { color: TagTypeName, text: string }[]
};

export const FactSheetsCard = ( props: FactSheetsCardProps ) => {
    const content =
        <div className={"factsheet-card" + (props.extraClass ? " " + props.extraClass : "")}>
            <div className="factsheet-card-text">
                {props.children}
            </div>
            <div className="factsheet-card-icon">
                { props.icon && !props.tags && <props.icon/> }
                { props.tags && props.tags.map( t => <div key={`tag-fs-card-${t.text.replace(/ /g, '_')}`}><Tag type={t.color}>{t.text}</Tag></div>) }
            </div>
            <div className="factsheet-card-link">
            { props.external ? <Launch16/> : <ArrowRight16/> }
            </div>
        </div>;
    if (props.external) {
        return <Link href={props.href} target="blank">{content}</Link>
    } else {
        return <NavLink to={props.href}>{content}</NavLink>
    }
}
